import React from 'react'
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material'
import StyledIconButton from '../Buttons/StyledIconButton'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import defaultImg from "../../assets/images/temp/no_img.jpeg"



type ProfileLogoType = {
    url?: string;
    isHover? : boolean;
    onClick: (e: React.MouseEvent) => void;
    label : string;
    height?: string;
    width?: string;
    isLoading? :boolean
}

const ProfleLogo = (props : ProfileLogoType) => {
  return (
      <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            position: 'relative', 
            "&:hover": {
                  "& .image_logo": {
                        opacity: 1,
                        transition: 'all 0.3s ease-in-out'
                  }} 
            }}
      >
            {
                  props.isLoading ? (
                        <Box sx={{width: props?.width ? props.width : '80px', height: props?.height ? props.height : '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#00000066', borderRadius: '50%'}} className="image_logo">
                              <CircularProgress size={30}/>
                        </Box>
                  ) : (
                        <>
                              <img
                                    src={props.url ? props.url : defaultImg}
                                    style={{ height: props.height ? props.height : '80px', width: props.width ? props.width : '80px', objectFit: 'cover', borderRadius: '100%', border: `2px solid gray` }}
                              />
                        {
                                    props.isHover ? (
                                          <Box sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#00000044', borderRadius: '50%', opacity: 0, transition: 'all 0.3s ease-in-out'}} className="image_logo">
                                                <StyledIconButton label={props.label} onClick={props.onClick}>
                                                      <CameraAltIcon fontSize="large" sx={{color: 'white'}}/>
                                                </StyledIconButton>
                                          </Box>
                                    ) : (
                                          <Tooltip title={props.label}>
                                                <IconButton onClick={props.onClick} color="primary" aria-label="upload" component="div">
                                                      <CameraAltIcon />
                                                </IconButton>
                                          </Tooltip>
                                    )
                              }
                        </>
                  )
            }
            
      </Box>
  )
}

export default ProfleLogo