import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useRedux";
import { fetchDataFail, fetchDataSuccess, fetchMatchEventDataFail, fetchMatchEventDataSuccess, fetchingData, fetchingMatchEventData, setCurrentPage, setFilterStatus, setMatchEventCurrentPage, setMatchEventFilterEventType, setMatchEventRowsPerPage, setMatchEventSearchTerm, setRowsPerPage, setSearchTerm, submitDataFail, submitDataSuccess, submittingData } from "./_reducer";
import apiUrl from "../../configs/_apiUrl";
import ApiRequest from "../../utils/apiRequest";
import { PlayerDataType } from "../../types/player";
import { getErrorMessageResponse } from "../../utils/response";
import { MatchDataType } from "../../types/match";
import { useNoti } from "../../providers";

const usePlayerAction = () => {
    const dispatch = useDispatch();
    const {showNoti} = useNoti();

    const searchTerms = useAppSelector(state => state.player.searchTerm);
    const currentPage = useAppSelector(state => state.player.currentPage);
    const rowsPerPage = useAppSelector(state => state.player.rowsPerPage);
    const status = useAppSelector(state => state.player.filters?.status);

    const matchEventSearchTerm = useAppSelector(state => state.player?.match_event?.searchTerm);
    const matchEventCurrentPage = useAppSelector(state => state.player?.match_event?.currentPage);
    const matchEventRowsPerPage = useAppSelector(state => state.player?.match_event?.rowsPerPage);
    const matchEventType = useAppSelector(state => state.player?.match_event?.filters?.event_type);

    const getPlayersByFilter = async () => {
        dispatch(fetchingData());
        const url = `${apiUrl.PLAYER}?search_term=${searchTerms}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${status}`

        try {
            const res = await ApiRequest.get(url);
            const data : PlayerDataType[] = res.data.payload.data;
            const metadata = res.data.payload.metadata;
            dispatch(fetchDataSuccess({data, total_rows: metadata.total_rows}));
        } catch (error) {
            console.error(error);
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg));
        }
    }

    const getPlayerDetail = async (player_id : string) => {
        dispatch(fetchingData());
        const url = `${apiUrl.PLAYER}/${player_id}`;

        try {
            const res = await ApiRequest.get(url);
            const data : PlayerDataType = res.data.payload.data;
            dispatch(fetchDataSuccess({player : data}));
            return data;
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg));
            return Promise.reject(msg);
        }
    }

    const playerUpdate = async (payloadData : any, player_id: string) => {
        dispatch(submittingData());
        const url = `${apiUrl.PLAYER}/${player_id}`;

        try {
            const res = await ApiRequest.put(url, payloadData);
            const data : PlayerDataType = res.data.payload.data;
            dispatch(submitDataSuccess());
            await getPlayerDetail(player_id);
            showNoti('Player data is edited successfully!', 'success');
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error");
            return Promise.reject(msg);
        }
    }

    const transferPlayerTeam = async (payloadData : {team_id : string, shirt : number}, player_id : string) => {
        dispatch(submittingData());
        const url = `${apiUrl.PLAYER}/${player_id}/transfer_team`;

        try {
            await ApiRequest.post(url, payloadData);
            dispatch(submitDataSuccess());
            showNoti("Transfer player success", "success");
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, 'error')
            return Promise.reject(msg);
        }
    }

    const uploadPlayerProfile = async (formData : FormData, player_id : string) => {
        dispatch(submittingData());
        const url = `${apiUrl.PLAYER}/upload_image`;

        try {
            await ApiRequest.post(url, {
                type : formData.get('type'),
                file : formData.get('file'),
                id : player_id
            }, {
                headers: {
                    'Content-Type':'multipart/form-data'
                }
            });
            dispatch(submitDataSuccess());
            showNoti("Profile is successfully uploaded", "success");
            await getPlayerDetail(player_id);
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error");
            return Promise.reject(msg);
        }
    }

    // Match Event
    const getMatchEventsByPlayer = async (player_id : string) => {
        dispatch(fetchingMatchEventData());
        const url = `${apiUrl.PLAYER}/${player_id}/events?search_term=${matchEventSearchTerm}&page_no=${matchEventCurrentPage + 1}&page_size=${matchEventRowsPerPage}&filters[player_id]=${player_id}&filters[event_type]=${matchEventType}`

        try {
            const res = await ApiRequest.get(url);
            const data : MatchDataType[] = res.data.payload.data;
            const metadata = res.data.payload.metadata;
            dispatch(fetchMatchEventDataSuccess({data, total_rows: metadata.total_rows}));
        } catch (error : unknown) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchMatchEventDataFail(msg));
        }
    }

    const getPlayerListByTeam = async (teamId: string): Promise<PlayerDataType[]> => {
        const url = `${apiUrl.TEAM}/${teamId}/players`;

        try {
            const res = await ApiRequest.get(url);
            const data : PlayerDataType[] = res.data.payload.data;
            return data;
        } catch (error : unknown) {
            const msg = getErrorMessageResponse(error);
            return Promise.reject(msg);
        }
    }

    const changeSearchTerm = (search_key : string) => {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key))
    }

    const changeCurrentPage = (pageNo : number) => {
        dispatch(setCurrentPage(pageNo))
    }

    const changeRowsPerPage = (rows_perPage : number) => {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rows_perPage))
    }

    const changeFilterStatus = (status : string) => {
        dispatch(setFilterStatus(status));
    }


    const changeMatchEventSearchTerm = (search_key : string) => {
        dispatch(setMatchEventSearchTerm(search_key))
    }

    const changeMatchEventCurrentPage = (pageNo : number) => {
        dispatch(setMatchEventCurrentPage(pageNo))
    }

    const changeMatchEventRowsPerPage = (rows_perPage : number) => {
        dispatch(setMatchEventCurrentPage(0));
        dispatch(setMatchEventRowsPerPage(rows_perPage))
    }

    const changeMatchEventType = (event_type : string) => {
        dispatch(setMatchEventFilterEventType(event_type))
    }

    return {
        getPlayersByFilter,
        getPlayerDetail,
        playerUpdate,
        transferPlayerTeam,
        uploadPlayerProfile,
        getMatchEventsByPlayer,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
        changeFilterStatus,
        changeMatchEventCurrentPage,
        changeMatchEventSearchTerm,
        changeMatchEventRowsPerPage,
        changeMatchEventType,
        getPlayerListByTeam
    }
}

export default usePlayerAction;