import React from 'react';
import { ListItemIcon, Tooltip, ListItemButton, ListItemText, ListItem, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import Icon from '@mui/material/Icon';
import { useNavigate } from 'react-router-dom';
import { useLayoutSetting, useThemeSetting } from '../../features';

type ChildType = {
    fe_route?: string;
    iconName?: string;
    menuName?: string;
    [key: string] : any
}

type MenuPropsType = {
    route : string;
    icon : string;
    title : string;
    child: ChildType[]
}

const MenuListItem = (props : MenuPropsType) => {
      const [open, setOpen] = React.useState(false);
      const { mode } = useThemeSetting();
      const { isOpenDrawer } = useLayoutSetting();
      let navigate = useNavigate();

      const handleOnParentMenuClick = (route : string) => {
            props.child?.length > 0
                  ? setOpen(!open)
                  : navigate(route)
      };

      const handleOnChildMenuClick = (route : string) => {
            navigate(route)
      };

      return (
            <>
                  <ListItem disablePadding>
                        <NewListItemButton
                              isCollapse={isOpenDrawer}
                              onClick={() => handleOnParentMenuClick(props.route)}
                              icon={props.icon}
                              title={props.title} />
                  </ListItem>

                  {
                        props.child?.length > 0 &&
                        <Collapse in={open} timeout="auto" unmountOnExit sx={{ bgcolor: mode === 'light' ? '#F1F1F1' : '#1d1f23' }}>
                              {open && <ArrowBox mode={mode} />}
                              {
                                    props.child.map((item, i) =>
                                          <NewListItemButton
                                                key={i}
                                                size={'small'}
                                                isCollapse={isOpenDrawer}
                                                onClick={() => handleOnChildMenuClick(item?.fe_route ?? '')}
                                                icon={item?.iconName ?? ''}
                                                title={item?.menuName ?? ''} />
                                    )
                              }
                        </Collapse>
                  }
            </>
      );

}

type NewListItemButtonPropsType = {
    size?: string;
    isCollapse : boolean;
    icon : string;
    title : string;
    onClick : React.MouseEventHandler<HTMLDivElement> | undefined
}

const NewListItemButton = (props : NewListItemButtonPropsType) => {

      return (
            <Tooltip title={props.title} placement="right" arrow>
                  <ListItemButton onClick={props.onClick} sx={{ height: props.size == 'small' ? 45 : 56 }}>
                        <ListItemIcon sx={{ ml: props.size == 'small' ? 1.5 : 1 }}>
                              <Icon style={{ fontSize: props.size == 'small' ? 20 : 25, color: 'gray' }}>
                                    {props.icon}
                              </Icon>
                        </ListItemIcon>
                        {
                              props.isCollapse &&
                              <ListItemText sx={{ ml: -1 }} primary={props.title} primaryTypographyProps={{ color: 'textPrimary', fontWeight: 'small', variant: props.size == 'small' ? 'body2' : 'body1' }} />
                        }
                  </ListItemButton>
            </Tooltip>
      )
}

const ArrowBox = ({ mode } : {mode : "light" | "dark"}) => {
      return (
            <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center'
            }}>
                  <Box sx={{
                        width: 0,
                        height: 0,
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        borderBottom: mode === 'light' ? '20px solid #F1F1F1' : '20px solid #1d1f23',
                        position: 'absolute'
                  }} />
            </Box>
      );
}


export default MenuListItem;
