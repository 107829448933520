import React from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { toCapitalize } from "../../utils/string";
import { useBreadCrumb } from "../../features";

export default function BreadCrumb() {
      const location = useLocation();
      const navigate = useNavigate();
      const { steps, setBreadCrumbStep } = useBreadCrumb();
      const [currentStep, setCurrentStep] = React.useState(0);

      React.useEffect(() => {
            const paths = location.pathname.split('/'); // REDUX
            const bc_name = location?.state?.name ?? 'unknown';// REDUX
            const len = paths.length;// REDUX

            function t(type : number) {
                  return type == 1
                        ? { label: toCapitalize(paths[1]), path: location.pathname }
                        : { label: bc_name, path: location.pathname , hash: location?.state?.hash }
            }

            if (len == 2) {
                  // STEP#1
                  setBreadCrumbStep({ A: t(1), B: null, C: null, D: null }) // REDUX
                  setCurrentStep(1); // REDUX
            } else if (len == 3) {
                  // STEP#2
                  setBreadCrumbStep({ A: steps.A, B: t(2), C: null, D: null })
                  setCurrentStep(2);
            } else if (len == 5) {
                  // STEP#3
                  setBreadCrumbStep({ A: steps.A, B: steps.B, C: t(2), D: null })
                  setCurrentStep(3);
            } else if (len == 7) {
                  // STEP#4
                  setBreadCrumbStep({ A: steps.A, B: steps.B, C: steps.C, D: t(2) })
                  setCurrentStep(4);
            } else {
                  // STEP#N/A
                  setBreadCrumbStep({ A: null, B: null, C: null, D: null })
                  setCurrentStep(0);
            }
      }, [location]);

      return (
            currentStep > 1
                  ? <Breadcrumbs aria-label="breadcrumb">
                        {Object.entries(steps).map((i, idx) => {
                              let locs : any = i[1];
                              let label = locs?.label;
                              //let path = locs?.path;`
                              let hash = location?.state?.hash;
                              let path = hash ? locs?.path + hash : locs?.path;
                              return (
                                    locs
                                          ? (idx + 1) == currentStep
                                                ? <Typography key={idx} color="text.primary">
                                                      {label ?? 'what is this?'}
                                                </Typography>
                                                : <Link key={idx} sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => navigate(path, { state: { name: label } })}>
                                                      {label ?? 'what is this?'}
                                                </Link>
                                          : null
                              );
                        })}
                  </Breadcrumbs>
                  : <Box sx={{ height: '24px' }} />
      );
}
