import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { DashboardDataType, DashboardTeamDataType, DashboardTopScoreDataType, StatsType } from "../../types/dashboard"

const initialState : DashboardDataType = {
    top_teams : [],
    top_scorers : [],

    stats: {},

    isLoading: false,
    isError : false,
    errorMessage : '',
}

export const dashboardSlice = createSlice({
    name : 'dashboard',
    initialState,
    reducers : {
        setData : (state, action : PayloadAction<{top_teams : DashboardTeamDataType[], top_scorers : DashboardTopScoreDataType[]}>) => {
            const {top_teams, top_scorers} = action.payload;
            if(top_teams) {
                state.top_teams = top_teams;
            }
            if(top_scorers) {
                state.top_scorers = top_scorers;``
            }
        },

        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action : PayloadAction<{top_teams : DashboardTeamDataType[], top_scorers : DashboardTopScoreDataType[], stats: StatsType}>) => {
            const {top_teams, top_scorers, stats} = action.payload;
            if(top_teams) {
                state.top_teams = top_teams;
            }
            if(top_scorers) {
                state.top_scorers = top_scorers;
            }

            if(stats) {
                state.stats = stats;
            }

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action : PayloadAction<string>) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
    }
})

export const {
    setData,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail
} = dashboardSlice.actions;

export default dashboardSlice.reducer;