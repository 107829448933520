import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DefaultPagationInitailType } from "../../types/redux";
import { PlayerDataType } from "../../types/player";
import { MatchDataType } from "../../types/match";

type PlayerPayloadAction = PayloadAction<PlayerDataType[]>

const initialState : DefaultPagationInitailType<PlayerDataType> = {
    data : [],

    info: null,
    
    totalRows: 0,
    rowsPerPage: 5,
    currentPage: 0,
    searchTerm: '',

    isLoading: false,
    isError: false,
    errorMessage: '',
    match_event : {
        data : [],

        isLoading : false,
        isError : false,
        errorMessage : '',
        
        totalRows : 0,
        rowsPerPage : 5,
        currentPage: 0,
        searchTerm: '',
        filters : {
            event_type : 'goal'
        }
    },
    action: {
        isError: false,
        isLoading: false,
        errorMessage: '',
    },
    filters: {
        status : 'active'
    }
}

const playerSlice = createSlice({
    name : 'player',
    initialState,
    reducers: {
        setData : (state, action : PlayerPayloadAction) => {
            state.data = action.payload
        },
        
        removeData : (state, action : PlayerPayloadAction) => {
            state.data = action.payload
        },

        setTotalRows : (state, action: PayloadAction<number>) => {
            state.totalRows = action.payload
        },
        
        setCurrentPage : (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },

        setRowsPerPage : (state, action : PayloadAction<number>) => {
            state.rowsPerPage = action.payload
        },

        setSearchTerm : (state , action : PayloadAction<string>) => {
            state.searchTerm = action.payload
        },

        setFilterStatus : (state, action : PayloadAction<string>) => {
            if(state.filters && state.filters?.status) {
                state.filters.status = action.payload
            }
        },

        submittingData : (state) => {
            if(state.action) {
                state.action.isLoading = true;
                state.action.isError = false;
                state.action.errorMessage = ''
            }
        },

        submitDataSuccess : (state) => {
            if(state.action) {
                state.action.isLoading = false;
                state.action.isError = false;
            }
        },

        submitDataFail : (state, action : PayloadAction<string>) => {
            if(state.action) {
                state.action.isError = true;
                state.action.isLoading = false;
                state.action.errorMessage = action.payload
            }
        },

        submittingCancel : (state) => {
            if(state.action) {
                state.action.isError = false;
                state.action.isLoading = false;
                state.action.errorMessage = ''
            }
        },

        fetchingData : (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess : (state, action : PayloadAction<{data? : PlayerDataType[], total_rows?: number, player? : PlayerDataType}>) => {
            const {data, total_rows, player} = action.payload;
            if(data) {
                state.data = data;
              }
            if(total_rows){
                state.totalRows = total_rows
            }
            if(player) {
                state.info = player
            }
            state.isLoading = false;
            state.isError = false;
        },

        fetchDataFail : (state, action : PayloadAction<string>) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },

        // =============== [ MATCH EVENT ] ================ //

        setMatchEventTotalRows : (state, action: PayloadAction<number>) => {
            state.match_event.totalRows = action.payload
        },

        setMatchEventCurrentPage : (state, action: PayloadAction<number>) => {
            state.match_event.currentPage = action.payload
        },

        setMatchEventRowsPerPage : (state, action : PayloadAction<number>) => {
            state.match_event.rowsPerPage = action.payload
        },

        setMatchEventSearchTerm : (state , action : PayloadAction<string>) => {
            state.match_event.searchTerm = action.payload
        },

        setMatchEventFilterEventType : (state , action : PayloadAction<string> ) => {
            if(state.match_event.filters && state.match_event.filters?.event_type) {
                state.match_event.filters.event_type = action.payload
            }
        },

        fetchingMatchEventData : (state) => {
            state.match_event.isLoading = true;
            state.match_event.isError = false;
        },

        fetchMatchEventDataSuccess : (state, action : PayloadAction<{data? : MatchDataType[], total_rows?: number}>) => {
            const {data, total_rows} = action.payload;

            if(data) {
                state.match_event.data = data;
            }
            if(total_rows) {
                state.match_event.totalRows = total_rows
            }

            state.match_event.isLoading = false;
            state.match_event.isError = false;
        },

        fetchMatchEventDataFail : (state, action : PayloadAction<string>) => {
            state.match_event.isLoading = false;
            state.match_event.isError = true;
            state.match_event.errorMessage = action.payload;
        }
    }
})

export const {
    setData,
    removeData,
    setTotalRows,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setFilterStatus,

    setMatchEventTotalRows,
    setMatchEventCurrentPage,
    setMatchEventRowsPerPage,
    setMatchEventSearchTerm,
    setMatchEventFilterEventType,

    submittingData,
    submitDataSuccess,
    submitDataFail,
    submittingCancel,

    fetchingData,
    fetchDataSuccess,
    fetchDataFail,

    fetchMatchEventDataFail,
    fetchMatchEventDataSuccess,
    fetchingMatchEventData
} = playerSlice.actions;

export default playerSlice.reducer;