import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react'
import { Controller } from 'react-hook-form'

type TextFieldControllerProps = {
    name : string;
    control : any;
    errmsg : string | undefined;
    defaultValue?: any;
    label?: string;
    type?: TextFieldProps["type"];
    placeholder?: string;
    sx? : TextFieldProps["sx"],
    adornment?: string | React.ReactNode;
    onChange? : (e : React.ChangeEvent) => void;
    slotProps? : TextFieldProps,
    disabled? : boolean;
}

const TextFieldController : React.FC<TextFieldControllerProps> = (props) => {
  return (
    <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue ?? ''}
        render={({field}) => (
            <TextField
                {...field}
                disabled={props.disabled ? true : false}
                fullWidth
                label={props.label}
                type={props.type ?? 'text'}
                size='small'
                placeholder={props.placeholder}
                sx={{
                    '& .MuiOutlinedInput-input': {
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none'
                        },
                    },
                    ...props.sx
                }}
                error={Boolean(props?.errmsg)}
                helperText={props.errmsg}
                FormHelperTextProps={{
                    sx: {
                        color:'red'
                    }
                }}
                InputProps={{
                    endAdornment: (props.adornment && <InputAdornment position="start">{
                        typeof props.adornment === 'string' ? props.adornment : props.adornment
                    }</InputAdornment>)
                }}
                onChange={(e) => {
                    props.onChange ? 
                    field.onChange(props.onChange(e)) 
                    : 
                    field.onChange(e)
                }}
                {...props.slotProps}
            />
        )}
    />
  )
}

export default TextFieldController