import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type StepType = {
    label: string;
    path: string;
    hash? : string
}

export type InitialStateType = {
    steps: {
      A: StepType | null;
      B: StepType | null;
      C: StepType | null;
      D: StepType | null;
    };
};

const initialState : InitialStateType = {
    steps : {
        A : null,
        B : null,
        C : null,
        D : null
    }
}

const breadCrumbSlice = createSlice({
    name: 'breadCrumb',
    initialState,
    reducers: {
        setStep: (state, action : PayloadAction<InitialStateType["steps"]>) => {
            state.steps = action.payload
        }
    }
});

export const {
    setStep,
} = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;