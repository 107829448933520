import {
      InitialStateType,
      setStep
} from "./_reducers";
import { useAppDispatch } from "../../hooks/useRedux";

const useBreadCrumbActions = () => {
      const dispatch = useAppDispatch();

      function setBreadCrumbStep(steps : InitialStateType["steps"]){
            dispatch(setStep(steps))
      }

      return {
            setBreadCrumbStep,
      }
}

export default useBreadCrumbActions;