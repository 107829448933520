import { useSelector } from 'react-redux';
import useThemeSettingActions from './_action';
import { RootState } from '../../types/store';

export const useThemeSetting = () => {
      const { changeMode, toggleLeftSideBar, openDrawer } = useThemeSettingActions();

      const mode: ("light"|"dark") = useSelector((state: RootState)  => state.theme.mode);
      const isCollapseSideBar: boolean = useSelector((state: RootState) => state.theme.collapseSideBar)
      const isOpenDrawer: boolean = useSelector((state: RootState) => state.theme.isOpenDrawer)
      const width: string = useSelector((state: RootState) => state.theme.width)

      return {
            // Actions
            changeMode,
            toggleLeftSideBar,
            openDrawer,
            
            // States
            mode,
            isCollapseSideBar,
            isOpenDrawer,
            width
      }
};


export { default as themeSettingReducer } from './_reducer';