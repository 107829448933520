import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

type AutocompleteControllerProps = {
    name : string;
    fullWidth? : boolean;
    control : any;
    options : { label : string; value: string }[];
    label : string;
    placeholder? : string;
    errmsg : string | undefined;
    onChange? : (value : string | undefined) => void;
}

const AutoCompleteController : React.FC<AutocompleteControllerProps> = (props) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({field}) => (
        <Autocomplete
            fullWidth={props?.fullWidth ? true : false}
            {...field}
            size="small"
            sx={{minWidth: 200}}
            options={props.options}
            getOptionLabel={(option) => option.label}
            value={props.options.find(option => option.value === field.value) ?? null}
            onChange={(event, value) => {
              props?.onChange ? 
              field.onChange(props?.onChange(value?.value)) : field.onChange(value?.value)
            }}

            renderInput={(params) => (
              <TextField 
                {...params} 
                label={props.label}
                placeholder={props?.placeholder ?? ''} 
                error={Boolean(props.errmsg)}
                helperText={props.errmsg ? props.errmsg : ''}
            />
        )}
        />
      )}
    />
  )
}

export default AutoCompleteController