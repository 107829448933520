import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import Logout from '@mui/icons-material/Logout';
import { useUser } from '../../features';
import { useNavigate } from 'react-router-dom';
import route from '../../configs/_route';

import UserAvatar from "../../assets/images/user.png";

export default function AccountMenuPopper(): React.ReactElement {
      const { userData, logoutUser } = useUser();
      const [anchorEl, setAnchorEl] = React.useState<any>(null);
      const open = Boolean(anchorEl);
      let navigate = useNavigate();

      function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
            setAnchorEl(event.currentTarget);
      }

      const handleClose = (): void => {
            setAnchorEl(null);
      };

      return (
            <>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Account settings">
                              <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}  >
                                    <Avatar
                                          alt="accountAvator"
                                          sx={{ width: 32, height: 32 }}
                                          src={UserAvatar}
                                    />
                              </IconButton>
                        </Tooltip>
                  </Box>
                  <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                              elevation: 0,
                              sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                          width: 32,
                                          height: 32,
                                          ml: -0.5,
                                          mr: 1,
                                    },
                                    '&:before': {
                                          content: '""',
                                          display: 'block',
                                          position: 'absolute',
                                          top: 0,
                                          right: 14,
                                          width: 10,
                                          height: 10,
                                          bgcolor: 'background.paper',
                                          transform: 'translateY(-50%) rotate(45deg)',
                                          zIndex: 0,
                                    },
                              },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                        <MenuItem>
                              <ListItemIcon>
                                    <PersonIcon fontSize="small" />
                              </ListItemIcon>
                              {userData.name}
                        </MenuItem>
                        <MenuItem>
                              <ListItemIcon>
                                    <EmailIcon fontSize="small" />
                              </ListItemIcon>
                              {userData.email}
                        </MenuItem>
                        <MenuItem onClick={() => navigate(route.CHANGE_PASSWORD)} >
                              <ListItemIcon>
                                    <KeyIcon fontSize="small" />
                              </ListItemIcon>
                              Change Password
                        </MenuItem>
                        <MenuItem onClick={logoutUser} >
                              <ListItemIcon>
                                    <Logout fontSize="small" />
                              </ListItemIcon>
                              Logout
                        </MenuItem>
                  </Menu>
            </>
      );
}