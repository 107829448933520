import { useSelector } from "react-redux";
import useUserActions  from './_action';
import { RootState } from "../../types/store";

export const useUser = () => {
    const {
        logIn,
        logoutUser,
        changePassword,
        sendResetPasswordMail,
        verifyToken,
        resetPassword
    } = useUserActions();

    const isAuth:boolean = useSelector((state: RootState) => state.user.isAuth);
    const userData: any = useSelector((state: RootState)=> state.user.data);
    const isLoading: boolean = useSelector((state: RootState)=> state.user.isLoading);
    const isSuccess: boolean = useSelector((state: RootState)=> state.user.isSuccess);
    const isError: boolean = useSelector((state: RootState)=> state.user.isError);
    const errorMsg: string = useSelector((state: RootState)=> state.user.errorMsg);

    return {
        //state
        isAuth,
        userData,
        isLoading,
        isSuccess,
        isError,
        errorMsg,

        //actions
        logIn,
        logoutUser,
        changePassword,
        sendResetPasswordMail,
        verifyToken,
        resetPassword
    }
}

export {default as userReducer} from './_reducer';