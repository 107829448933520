import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DefaultPagationInitailType } from "../../types/redux";
import { MatchDataType } from "../../types/match";

type MatchPayloadAction = PayloadAction<MatchDataType[]>

const initialState : DefaultPagationInitailType<MatchDataType>  = {
    data: [],

    info : null,

    totalRows: 0,
    rowsPerPage: 5,
    currentPage: 0,
    searchTerm: '',
    status: "completed",

    isLoading: false,
    isError: false,
    errorMessage: '',
    action: {
        isError: false,
        isLoading: false,
        errorMessage: ''
    }
}

export const matchSlice = createSlice({
    name: "match",
    initialState,
    reducers : {
        setData : (state, action : MatchPayloadAction) => {
            state.data = action.payload
        },
        
        removeData : (state, action : MatchPayloadAction) => {
            state.data = action.payload
        },

        setTotalRows : (state, action: PayloadAction<number>) => {
            state.totalRow = action.payload
        },
        
        setCurrentPage : (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },

        setRowsPerPage : (state, action : PayloadAction<number>) => {
            state.rowsPerPage = action.payload
        },

        setSearchTerm : (state , action : PayloadAction<string>) => {
            state.searchTerm = action.payload
        },

        setStatusFilter: (state , action : PayloadAction<"completed"|"schedule"|"draft"|null>) => {
            state.status = action.payload;
        },

        submittingData : (state) => {
            if(state.action) {
                state.action.isLoading = true;
                state.action.isError = false;
                state.action.errorMessage = ''
            }
        },

        submitDataSuccess : (state) => {
            if(state.action) {
                state.action.isLoading = false;
                state.action.isError = false;
            }
        },

        submitDataFail : (state, action : PayloadAction<string>) => {
            if(state.action) {
                state.action.isError = true;
                state.action.isLoading = false;
                state.action.errorMessage = action.payload
            }
        },

        submittingCancel : (state) => {
            if(state.action) {
                state.action.isError = false;
                state.action.isLoading = false;
                state.action.errorMessage = ''
            }
        },

        fetchingData : (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess : (state, action : PayloadAction<{data ?: MatchDataType[], total_rows?: number, match? : MatchDataType}>) => {
          const {data, total_rows, match} = action.payload;
          if(data) {
            state.data = data;
          }
          if(total_rows){
            state.totalRows = total_rows
          }
          if(match) {
            state.info = match
          }
          state.isLoading = false;
          state.isError = false;
        },

        fetchDataFail : (state, action : PayloadAction<string>) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        },
      
    }
})


export const {
    setData,
    removeData,
    setTotalRows,
    setStatusFilter,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    submittingCancel,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail
} = matchSlice.actions;

export default matchSlice.reducer;