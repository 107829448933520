import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MatchEvent } from "../../types/matchEvent";
import { DefaultNormalInitailType } from "../../types/redux";

const initialState: DefaultNormalInitailType<MatchEvent>  = {
    data: [],

    isLoading: false,
    isError: false,
    errorMessage: '',
    action: {
        isError: false,
        isLoading: false,
        errorMessage: ''
    }
}

export const matchEventSlice = createSlice({
    name: "match_events",
    initialState,
    reducers : {
        setData : (state, action: PayloadAction<MatchEvent[]>) => {
            state.data = action.payload;
        },
        
        removeData : (state, action: PayloadAction<MatchEvent[]>) => {
            state.data = action.payload
        },

        submittingData : (state) => {
            if(state.action) {
                state.action.isLoading = true;
                state.action.isError = false;
                state.action.errorMessage = ''
            }
        },

        submitDataSuccess : (state) => {
            if(state.action) {
                state.action.isLoading = false;
                state.action.isError = false;
            }
        },

        submitDataFail : (state, action : PayloadAction<string>) => {
            if(state.action) {
                state.action.isError = true;
                state.action.isLoading = false;
                state.action.errorMessage = action.payload
            }
        },

        submittingCancel : (state) => {
            if(state.action) {
                state.action.isError = false;
                state.action.isLoading = false;
                state.action.errorMessage = ''
            }
        },

        fetchingData : (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess : (state, action : PayloadAction<{data : MatchEvent[], total_rows?: number}>) => {
          action.payload.data && (state.data = action.payload.data);
          if(action.payload.total_rows){
            state.totalRows = action.payload.total_rows
          }
          state.isLoading = false;
          state.isError = false;
        },

        fetchDataFail : (state, action : PayloadAction<string>) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        },
      
    }
})


export const {
    setData,
    removeData,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    submittingCancel,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail
} = matchEventSlice.actions;

export default matchEventSlice.reducer;