import apiUrl from "../../configs/_apiUrl";
import { useAppDispatch } from "../../hooks/useRedux"
import ApiRequest from "../../utils/apiRequest";
import { getErrorMessageResponse } from "../../utils/response";
import { fetchDataFail, fetchDataSuccess, fetchingData } from "./_reducer";

const useDashboardAction = ()=> {
    const dispatch = useAppDispatch();

    const getDashboardData = async () => {
        dispatch(fetchingData());
        const url = `${apiUrl.DASHBOARD}/backoffice`;
        
        try {
            const res = await ApiRequest.get(url);
            const data = res.data.payload.data;
            dispatch(fetchDataSuccess({top_teams: data?.standings ?? [], top_scorers: data?.top_scorers ?? [], stats: data?.stats}));
            return Promise.resolve();
        } catch (error : unknown) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg));
            return Promise.reject(msg);
        }
    }

    return {
        getDashboardData
    }
}

export default useDashboardAction;