import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';

type DatePickerControllerType = {
  name: string;
  control: any;
  label: string;
  placeholder: string;
  errmsg: string | undefined;
  onChange?: (e: Dayjs | null) => void;
};

const DatePickerController = (props: DatePickerControllerType) => {
  const { name, control, errmsg } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <DatePicker
            {...field}
            label={props.label}
            format="YYYY-MM-DD"
            value={dayjs(field.value) ?? undefined}
            slotProps={{
              textField: {
                helperText: errmsg ?? '',
                error: Boolean(errmsg),
                size: 'small',
                placeholder: props.placeholder
              },
            }}
            onChange={(date) => {
              props.onChange
                ? field.onChange(props.onChange(date))
                : field.onChange(date);
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerController;
