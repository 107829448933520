
import React from 'react';
import { DialogActions, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
/**
 * # Dailog Action
 * @param {Component} children area to place actions buttons
 * @param {Boolean} isError
 * @param {String} errMsg
 * @returns 
 * ## Sample Usage
 * ```js
 * <Dialog>
 *    <StyledDialogAction isError={boolean} errMsg={string}>
 *          `<children /> - place action button here `
 *    </StyledDialogAction>
 * </Dialog>
 * ```
 */

type StyledDialogActionType = {
    isError ?: boolean;
    errMsg ?: string;
    children : React.ReactNode;
}

const StyledDialogAction = (props : StyledDialogActionType) => {
      return (
            <>
                  <Divider />
                  <DialogActions sx={{ p: '8px 24px 8px 24px' }}>
                        {
                              props.isError &&
                              <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    📍  {props.errMsg}
                              </Typography>
                        }
                        <Box sx={{ flexGrow: 1 }} />
                        {props.children}
                  </DialogActions>
            </>
      )
}

export default StyledDialogAction;