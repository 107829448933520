import { useDispatch } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import apiUrl from "../../configs/_apiUrl";

import {
  fetchDataFail,
  loginSuccess,
  fetchingData,
  setAuth,
  fetchDataSuccess
} from "./_reducer";
import tokenName from "../../constants/token";

const useUserActions = () => {
  const dispatch = useDispatch();

  async function logIn(data: { email: string, password: string, token: (string | null) }): Promise<{ isFirstLogin: boolean }> {
    dispatch(fetchingData());
    try {
      let res = await ApiRequest.post(apiUrl.USER_LOGIN, data);
      const resData = res?.data?.payload?.data;
      dispatch(loginSuccess(resData));
      return Promise.resolve({ isFirstLogin: resData.userData.is_first_login })
    }
    catch (err: any) {
      const errMsg = err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    } finally {
      window.localStorage.setItem("currentVersion", process.env.REACT_APP_VERSION || "0");
    }
  }

  function logoutUser(): void {
    localStorage.removeItem(tokenName.ACCESS_TOKEN);
    localStorage.removeItem(tokenName.REFRESH_TOKEN);

    localStorage.setItem("currentVersion", process.env.REACT_APP_VERSION ?? "");

    dispatch(setAuth(false));
  }

  async function changePassword(data: { oldPassword: string, newPassword: string, token: string }): Promise<void> {
    dispatch(fetchingData());
    try {
      await ApiRequest.put(apiUrl.CHANGE_PASSWORD, data);
      dispatch(fetchDataSuccess());
    }
    catch (err: any) {
      const errMsg = err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  async function sendResetPasswordMail(data: { mail: string }) {
    dispatch(fetchingData());
    try {
      await ApiRequest.post(apiUrl.SEND_RESET_MAIL, data);
      dispatch(fetchDataSuccess());
    } catch (err: any) {
      const errMsg = err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  async function verifyToken(token: string) {
    try {
      await ApiRequest.post(apiUrl.VALIDATE_RESET_TOKEN, { token });
    } catch (err: any) {
      const errMsg = err.response?.data.errors?.message ?? `Error - ${err.message}`;
      return Promise.reject(errMsg);
    }
  }

  async function resetPassword(data: { newPassword: string, token: string }) {
    dispatch(fetchingData());
    try {
      await ApiRequest.post(apiUrl.RESET_PASSWORD, data);
      dispatch(fetchDataSuccess());
    } catch (err: any) {
      const errMsg = err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  return {
    logIn,
    logoutUser,
    changePassword,
    sendResetPasswordMail,
    verifyToken,
    resetPassword
  };
};

export default useUserActions;
