import React from 'react';
import { IconButton, IconButtonProps, Tooltip } from "@mui/material"
import { useUser } from '../../features';

type IconButtonType = {
    actionName? : string;
    label : string;
    onClick : (e : React.MouseEvent) => void;
    sx? : IconButtonProps["sx"];
    disabled? : boolean;
    children: React.ReactNode
}

const StyledIconButton = (props : IconButtonType) => {
      const [showBtn, setShowBtn] = React.useState(true);

      React.useEffect(() => {
            if(props.actionName) {
                //   setShowBtn(hasPermission(props.actionName));
            } else {
                  setShowBtn(true);
            }
      }, [props.actionName]);

      return (
            showBtn ? (
                  <Tooltip title={props.label} arrow placement="top" followCursor>
                        <IconButton
                              {...props}
                              aria-label={'icon_button_lbl_' + props.label}
                              sx={{ ...(props.sx ?? {}), opacity: props.disabled ? 0.5 : 1 }}
                              onClick={props.onClick} >
                              {props.children}
                        </IconButton>
                  </Tooltip>
            )
            : null
      )
}

export default StyledIconButton;