import { useAppSelector } from "../../hooks/useRedux";
import useDashboardAction from "./_actions";


export const useDashboard = () => {
    const {getDashboardData} = useDashboardAction()


    const top_teams = useAppSelector(state => state.dashboard.top_teams);
    const top_scorers = useAppSelector(state => state.dashboard.top_scorers);
    const stats = useAppSelector(state => state.dashboard.stats)

    const isLoading = useAppSelector(state => state.dashboard.isLoading);
    const isError = useAppSelector(state => state.dashboard.isError);
    const errorMessage = useAppSelector(state => state.dashboard.errorMessage);

    return {
        getDashboardData,
        top_teams,
        top_scorers,
        stats,
        isLoading,
        isError,
        errorMessage,
    }
}


export {default as dashboardReducer} from "./_reducer"