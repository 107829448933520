import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React from 'react';

dayjs.extend(utc);
  
  export function formatVal(value: number | string, valueType : 'number' | 'date'): JSX.Element | string {
    if (valueType) {
      if (valueType === 'number') {
        const formattedNumber = value.toLocaleString(); // Assuming value is a number
       if(typeof value === "number") {
        return (
          <span style={{ color: value < 0 ? 'red' : 'inherit', display: 'inline' }}>
            {formattedNumber}
          </span>
        );
       }
      } else if (valueType === 'date') {
        return dayjs(value).utc().format('DD-MMM-YYYY hh:mm A');
      }
    }
    return value.toString(); // Handle other data types by converting to string
  }

  export function simpleDateFormat (date : string) : string {
    return dayjs(date).utc().format("MMM D")
  }

export function toCapitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function toFixed(num: number, fixedPlace = 1): string {
    return num.toFixed(fixedPlace);
}

export function toFormattedString(key: string, namingCase: 'camel' | 'snake'): string {

    enum LetterCase {
        CAMEL = 'camel',
        SNAKE = 'snake'
    }

    function getWordArray(str = ""): string[] {
        if (namingCase === LetterCase.SNAKE) {
            return str.split("_");
        }

        const asciiMap: [string, number][] = str.split("").map(char => [char, char.charCodeAt(0)]);

        const spaceString = asciiMap.reduce((str, map, index) => {
            const isCurCharUpperCase = (map[1] >= 65 && map[1] <= 90);
            const isNextCharUpperCase = Boolean(asciiMap[index + 1]) && (asciiMap[index + 1][1] >= 65 && asciiMap[index + 1][1] <= 90);
            str += (isCurCharUpperCase && !isNextCharUpperCase) ? ` ${map[0]}` : map[0];
            return str;
        }, "");

        return spaceString.trim().split(" ");
    }

    function getFinalString(wordArray: string[] = []): string {
        return (wordArray.reduce((str, word) => {
            str += `  ${isAllCharUpperCase(word) ? word : toCapitalize(word)}`;
            return str;
        }, "")).trim();
    }

    function isAllCharUpperCase(word = ""): boolean {
        return /^[A-Z]+$/.test(word);
    }

    return getFinalString(getWordArray(key));
}


export function removeNonNumeric(num: string | number): string {
    return num?.toString().replace(/[^0-9]/g, "") ?? "";
}

export function addCommas(num: string | number): string {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "";
}
