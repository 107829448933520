import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingBox = () => {
      return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: 'transparent'}}>
                  <CircularProgress/>
            </Box>
      )
};

export default LoadingBox;