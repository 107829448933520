// Libries
// Local
import { useAppSelector } from '../../hooks/useRedux';
import useLayoutSettingActions from './_action';
import { Variant } from './_reducer';

// Register all state here from the reducer to export
export const useLayoutSetting = () => {
      const { setAsMobileSize, openDrawer } = useLayoutSettingActions();

      const isMobile: boolean = useAppSelector((state) => state.layout.isMobile)
      const variant: Variant = useAppSelector((state) => state.layout.variant)
      const width: string = useAppSelector((state) => state.layout.width)
      const isCollapse: boolean = useAppSelector((state) => state.layout.isCollapse)
      const isOpenDrawer: boolean = useAppSelector((state) => state.layout.isOpenDrawer)

      return {
            // Actions
            setAsMobileSize,
            openDrawer,
            
            // States
            isMobile,
            variant,
            width,
            isCollapse,
            isOpenDrawer
      }
};


export { default as layoutSettingReducer } from './_reducer';