import { useAppSelector } from "../../hooks/useRedux";
import { PlayerDataType } from "../../types/player";
import usePlayerAction from "./_action"

export const usePlayer = () => {
    const {
        getPlayersByFilter,
        getPlayerDetail,
        playerUpdate,
        transferPlayerTeam,
        uploadPlayerProfile,
        getMatchEventsByPlayer,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeFilterStatus,
        changeMatchEventCurrentPage,
        changeMatchEventRowsPerPage,
        changeMatchEventSearchTerm,
        changeMatchEventType,
        getPlayerListByTeam
    } = usePlayerAction();

    const data = useAppSelector(state => state.player.data);
    const playerInfo : PlayerDataType = useAppSelector(state => state.player?.info);
    const totalRows = useAppSelector(state => state.player.totalRows);
    const rowsPerPage = useAppSelector(state => state.player.rowsPerPage);
    const currentPage = useAppSelector(state => state.player.currentPage);
    const searchTerm = useAppSelector(state => state.player.searchTerm);
    const isLoading = useAppSelector(state => state.player.isLoading)
    const isError = useAppSelector(state => state.player.isError)
    const errorMessage = useAppSelector(state => state.player.errorMessage);
    const status = useAppSelector(state => state.player.filters?.status);
    const action = useAppSelector(state => state.player.action);

    const match_event_data = useAppSelector(state => state.player?.match_event?.data);
    const match_event_totalRows = useAppSelector(state => state.player?.match_event?.totalRows);
    const match_event_rowsPerPage = useAppSelector(state => state.player?.match_event?.rowsPerPage);
    const match_event_currentPage = useAppSelector(state => state.player?.match_event?.currentPage);
    const match_event_searchTerm = useAppSelector(state => state.player?.match_event?.searchTerm);
    const match_event_isLoading = useAppSelector(state => state.player?.match_event?.isLoading);
    const match_event_isError = useAppSelector(state => state.player?.match_event?.isError);
    const match_event_errorMessage = useAppSelector(state => state.player?.match_event?.errorMessage);
    const match_event_type = useAppSelector(state => state.player.match_event?.filters?.event_type);

    return {
        getPlayersByFilter,
        getPlayerDetail,
        playerUpdate,
        transferPlayerTeam,
        uploadPlayerProfile,
        getMatchEventsByPlayer,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeFilterStatus,
        changeMatchEventCurrentPage,
        changeMatchEventRowsPerPage,
        changeMatchEventSearchTerm,
        changeMatchEventType,
        getPlayerListByTeam,


        data,
        playerInfo,
        totalRows,
        rowsPerPage,
        currentPage,
        searchTerm,
        isLoading,
        isError,
        errorMessage,
        status,
        action,

        // match
        match_event_data,
        match_event_totalRows,
        match_event_rowsPerPage,
        match_event_currentPage,
        match_event_searchTerm,
        match_event_isLoading,
        match_event_isError,
        match_event_errorMessage,
        match_event_type,
    }
}

export {default as playerReducer} from "./_reducer";

