enum route {
    LANDING = "/",
    DASHBOARD = "/dashboard",

    MATCH = "/matches",
    TEAM = '/teams',
    PLAYER = "/players",
    REFREE = "/referees",
    CHANGE_PASSWORD = "/change-password",
    RESET_PASSWORD = "/reset-password/:token"

}

export default route;