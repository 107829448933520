import React from 'react';
import { Chip, ChipProps, Theme } from '@mui/material';

const color = {
      success: {
            text: (theme : Theme) => theme.palette.success.main,
            background: '#d4edda'
      },
      error: {
            text: (theme : Theme) => theme.palette.error.main,
            background: '#f8d7da',
      },
      warning: {
            text: "#856404",
            background: "#fff3cd"
      },
      info: {
            text: "#17a2b8",
            background: "#d1ecf1"
      },
      primary: {
            text: "#604031",
            background: "#ddc9c0"
      },
      draft: {
            text: '#FFFFFF',
            background: '#666666'
      },
      submitted: {
            text: '#FFFFFF',
            background: '#666666'
      },
      confirm: {
            text: '#FFFFFF',
            background: '#579EF3'
      },
      readyToDeliver: {
            text: '#FFFFFF',
            background: '#603D86'
      },
      delivering: {
            text: '#FFFFFF',
            background: '#F4CC64'
      },
      complete: {
            text: '#FFFFFF',
            background: '#74B569'
      },
      cancel: {
            text: '#FFFFFF',
            background: '#FF7B7B'
      },
}

const getChipColor = (type : string) => {
      function getColor(colorType: 'text' | 'background') {
            return (
                  type == 'success' ? color.success[colorType] : 
                  type == 'error' ? color.error[colorType] : 
                  type == 'warning' ? color.warning[colorType] : 
                  type == 'info' ? color.info[colorType] : 
                  type == 'draft' ? color.draft[colorType] :
                  type == 'submitted' ? color.submitted[colorType] :
                  type == 'confirm' ? color.confirm[colorType] :
                  type == 'readyToDeliver' ? color.readyToDeliver[colorType] :
                  type == 'delivering' ? color.delivering[colorType] :
                  type == 'complete' ? color.complete[colorType] :
                  color.primary[colorType]
            )
      }
      return {
            text: () => getColor('text'),
            background: () => getColor('background')
      }
}

/**
 * 
 * @param {String} type success | error | warning | info | others
 * @param {String} text Text content 
 * @returns 
 */

interface StatusType {
    type : "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    text : string;
    sx? : ChipProps["sx"]
}

const StatusChip = ({type, text, sx={}} : StatusType) => {
      return (
            <Chip
                  sx={{
                        py: '1rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: getChipColor(type).text(),
                        bgcolor: getChipColor(type).background(),
                        fontWeight: 600,
                        borderRadius: '30px',
                        maxHeight: '23px',
                        textTransform: 'uppercase',
                        ...sx
                  }}
                  label={text} />
      )
}

export default StatusChip;