import { useAppSelector } from "../../hooks/useRedux"
import { TeamDataType } from "../../types/team";
import useTeamAction from "./_action";

export const useTeam = () => {

    const {
        getTeamByFilter,
        getTeamDetail,
        teamUpdate,
        getPlayersUnderTeam,
        createPlayerUnderTeam,
        uploadTeamImage,
        removePlayerUnderTeam,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changePlayerSearchTerm,
        changePlayerCurrentPage,
        changePlayerRowsPerPage,
        createTeam,

        getUpcomingMatchUnderTeam,
        changeUpcomingMatchCurrentPage,
        changeUpcomingMatchRowsPerPage,

        getResultMatchUnderTeam,
        changeResultMatchCurrentPage,
        changeResultMatchRowsPerPage,
        
    } = useTeamAction();

    const data = useAppSelector(state => state.team.data);
    const teamInfo : TeamDataType = useAppSelector(state => state.team?.info);
    const players_under_team = useAppSelector(state => state.team.player.data);
    const totalRows = useAppSelector(state => state.team.totalRows);
    const rowsPerPage = useAppSelector(state => state.team.rowsPerPage);
    const currentPage = useAppSelector(state => state.team.currentPage);
    const searchTerm = useAppSelector(state => state.team.searchTerm);
    const isLoading = useAppSelector(state => state.team.isLoading)
    const isError = useAppSelector(state => state.team.isError)
    const errorMessage = useAppSelector(state => state.team.errorMessage)
    const action = useAppSelector(state => state.team.action);

    const player_totalRows = useAppSelector(state => state.team.player.totalRows);
    const player_rowsPerPage = useAppSelector(state => state.team.player.rowsPerPage);
    const player_currentPage = useAppSelector(state => state.team.player.currentPage);
    const player_searchTerm = useAppSelector(state => state.team.player.searchTerm);
    const player_isLoading = useAppSelector(state => state.team.player.isLoading);
    const player_isError = useAppSelector(state => state.team.player.isError);
    const player_errorMessage = useAppSelector(state => state.team.player.errorMessage);


    const upcomingMatch_under_team = useAppSelector(state => state.team.upcomingMatch.data);
    const upcomingMatch_totalRows = useAppSelector(state => state.team.upcomingMatch.totalRows);
    const upcomingMatch_rowsPerPage = useAppSelector(state => state.team.upcomingMatch.rowsPerPage);
    const upcomingMatch_currentPage = useAppSelector(state => state.team.upcomingMatch.currentPage);
    const upcomingMatch_isLoading = useAppSelector(state => state.team.upcomingMatch.isLoading);
    const upcomingMatch_isError = useAppSelector(state => state.team.upcomingMatch.isError);
    const upcomingMatch_errorMessage = useAppSelector(state => state.team.upcomingMatch.errorMessage);

    const resultMatch_under_team = useAppSelector(state => state.team.resultMatch.data);
    const resultMatch_totalRows = useAppSelector(state => state.team.resultMatch.totalRows);
    const resultMatch_rowsPerPage = useAppSelector(state => state.team.resultMatch.rowsPerPage);
    const resultMatch_currentPage = useAppSelector(state => state.team.resultMatch.currentPage);
    const resultMatch_isLoading = useAppSelector(state => state.team.resultMatch.isLoading);
    const resultMatch_isError = useAppSelector(state => state.team.resultMatch.isError);
    const resultMatch_errorMessage = useAppSelector(state => state.team.resultMatch.errorMessage);

    return {
        getTeamByFilter,
        getTeamDetail,
        teamUpdate,
        createTeam,
        getPlayersUnderTeam,
        createPlayerUnderTeam,
        uploadTeamImage,
        removePlayerUnderTeam,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changePlayerSearchTerm,
        changePlayerCurrentPage,
        changePlayerRowsPerPage,

        getUpcomingMatchUnderTeam,
        changeUpcomingMatchCurrentPage,
        changeUpcomingMatchRowsPerPage,

        getResultMatchUnderTeam,
        changeResultMatchCurrentPage,
        changeResultMatchRowsPerPage,

        data,
        teamInfo,
        players_under_team,
        totalRows,
        rowsPerPage,
        currentPage,
        searchTerm,
        isLoading,
        isError,
        errorMessage,
        action,

        player_totalRows,
        player_rowsPerPage,
        player_currentPage,
        player_searchTerm,
        player_isLoading,
        player_isError,
        player_errorMessage,

        upcomingMatch_under_team,
        upcomingMatch_totalRows,
        upcomingMatch_rowsPerPage,
        upcomingMatch_currentPage,
        upcomingMatch_isLoading,
        upcomingMatch_isError,
        upcomingMatch_errorMessage,

        resultMatch_under_team,
        resultMatch_totalRows,
        resultMatch_rowsPerPage,
        resultMatch_currentPage,
        resultMatch_isLoading,
        resultMatch_isError,
        resultMatch_errorMessage
    }
}


export {default as teamReducer} from "./_reducer"