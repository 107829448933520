import React from "react";
import { Box, Skeleton } from "@mui/material";


function DashboardSkeleton(): React.ReactElement {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} gap={4}>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Skeleton variant='circular' width={22} height={22} sx={{ mr: 2 }} />
                <Skeleton width={307} height={35} />
                <Box sx={{ flexGrow: 1 }} />
                <Skeleton width={200} height={50} />
            </Box> */}

            <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: 4 }}>
                <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width={307} height={150} />
                <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width={307} height={150} />
                <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width={307} height={150} />
                <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width={307} height={150} />
            </Box>
            <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: '2fr 2fr', columnGap: 4 }}  >
                <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width={630} height={305} />
                <Skeleton sx={{ borderRadius: 1 }} variant="rectangular" width={630} height={305} />
            </Box>
        </Box>


    )
}

export default DashboardSkeleton;