import React from 'react';
import { DialogTitle, Divider, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

/**
 * # Dailog Header
 * @param {String} title 
 * @param {Boolean} isLoading
 * @param {Component} chips to place chip component
 * @param {Component} children area to place actions buttons
 * @returns 
 */

type DialogHeaderProps = {
    title: string,
    isLoading?: boolean,
    chips?: React.ReactNode,
    children?: React.ReactNode
}

const StyledDialogHeader = (props : DialogHeaderProps) => {
    return (
          <>
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '8px 24px 0px 24px' }} >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                            <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>{props.title}</Typography>
                            {props.chips}
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {props.children}
                      </Box>
                </DialogTitle>

                <Divider />
                {props.isLoading && <LinearProgress />}
          </>
    )
}

export default StyledDialogHeader;
