enum apiUrl {
    USER_LOGIN = "/auth/login", 
    TEAM = '/teams',

    Match = '/matches',

    PLAYER = '/players',
    REFREE = '/referees',
    CHANGE_PASSWORD = "/auth/change-password",
    SEND_RESET_MAIL = "/auth/reset-mail",
    VALIDATE_RESET_TOKEN = "/auth/validate-reset-token",
    RESET_PASSWORD = "/auth/reset-password",
    MATCH_EVENT = "/match-events",
    DASHBOARD = '/dashboard'
}

export default apiUrl;