import { createSlice } from "@reduxjs/toolkit";
export type Variant = "persistent" | "temporary";

type initialStateType = {
      isMobile: boolean,
      variant: Variant,  // or temporary
      width: string,
      isCollapse: boolean,
      isOpenDrawer: boolean,
}

const initialState: initialStateType = {
      isMobile: false,
      variant: 'persistent',
      width: '200px',
      isCollapse: true,
      isOpenDrawer: true,
}

// Slice
const layoutSettingSlice = createSlice({
      name: 'layout',
      initialState,
      reducers: {
            setAsMobile: (state, action) => {
                  if (action.payload) {
                        // if true - it is mobile
                        state.isMobile = true;
                        state.variant = 'temporary';
                        state.isCollapse = false;
                        state.width = '200px';
                  } else {
                        // if false - it is window
                        state.isMobile = false;
                        state.variant = 'persistent';
                        state.isCollapse = true;
                        state.width = '200px';
                  }
            },
            setOpenDrawer: (state, action) => {
                  if (state.isMobile) {
                        // if true - it is mobile 
                        state.isCollapse = action.payload;
                        state.isOpenDrawer = action.payload;
                  } else {
                        // if false - it is window
                        state.width = action.payload ? '200px' : '64px';
                        state.isOpenDrawer = action.payload;
                  }
            }
      },
});

// Export
export const { setAsMobile, setOpenDrawer } = layoutSettingSlice.actions;

export default layoutSettingSlice.reducer;