import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CryingTotoro from "../../assets/images/totoro/crying.gif"

type ErrorPropsType = {
    errorMsg: string;
    onError : () => void;

}

const ErrorBox = (props : ErrorPropsType) => {
      return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: 'transparent'}}>
                  <img style={{ width: 120, height: 120 }} alt={'img'} src={CryingTotoro} />
                  <Typography>{props.errorMsg}</Typography>
                  <Button variant='contained' color='error' onClick={props.onError}>Try Again</Button>
            </Box>
      )
};

export default ErrorBox;