import { createSlice } from "@reduxjs/toolkit";
import tokenName from "../../constants/token";

type initialStateType = {
    data: any,
    isAuth: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    errorMsg: string,
}

const initialState: initialStateType = {
    data: null,
    isAuth: false,
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMsg: '',
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        loginSuccess: (state, action) => {
            state.data = action.payload.userData;
            localStorage.setItem(tokenName.ACCESS_TOKEN, action.payload.accessToken);
            localStorage.setItem(tokenName.REFRESH_TOKEN, action.payload.refreshToken);
            state.isAuth = true;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        },
        fetchDataSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.errorMsg = action.payload
        }
    }
});

export const {
    setAuth,
    fetchingData,
    fetchDataFail,
    fetchDataSuccess,
    loginSuccess
} = userSlice.actions;

export default userSlice.reducer;