import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useRedux";
import { fetchDataFail, fetchDataSuccess, fetchingData, setCurrentPage, setFilterStatus, setRowsPerPage, setSearchTerm, submitDataFail, submitDataSuccess, submittingData } from "./_reducer";
import apiUrl from "../../configs/_apiUrl";
import ApiRequest from "../../utils/apiRequest";
import { RefreeDataType } from "../../types/refree";
import { getErrorMessageResponse } from "../../utils/response";
import { useNoti } from "../../providers";

const useRefreeAction = () => {
    const dispatch = useDispatch();
    const {showNoti} = useNoti();

    const searchTerms = useAppSelector(state => state.refree.searchTerm);
    const currentPage = useAppSelector(state => state.refree.currentPage);
    const rowsPerPage = useAppSelector(state => state.refree.rowsPerPage);

    const getRefreeList = async () => {
        dispatch(fetchingData());
        const url = `${apiUrl.REFREE}`

        try {
            const res = await ApiRequest.get(url);
            const data = res.data.payload.data;
            dispatch(fetchDataSuccess({}));
            return data;
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg));
        }
    }
    const getRefreesByFilter = async () => {
        dispatch(fetchingData());
        const url = `${apiUrl.REFREE}?search_term=${searchTerms}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`

        try {
            const res = await ApiRequest.get(url);
            const data : RefreeDataType[] = res.data.payload.data;
            const metadata = res.data.payload.metadata;
            dispatch(fetchDataSuccess({data, total_rows: metadata.total_rows}));
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg));
        }
    }

    const createReferee = async (refereeData : any) => {
        dispatch(submittingData());
        const url = `${apiUrl.REFREE}`

        try {
            const res = await ApiRequest.post(url, refereeData);
            const data = res.data.payload.data;
            dispatch(submitDataSuccess());
            return data;
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
        }
    }

    const deleteReferee = async (id : string) => {
        dispatch(submittingData());
        const url = `${apiUrl.REFREE}/${id}`;

        try {
            const res = await ApiRequest.delete(url);
            const data = res.data.payload.data;
            dispatch(submitDataSuccess());
            showNoti("Referee deleted successfully", "success");
            return data;
        } catch (error : unknown) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error");
            return Promise.reject(msg);
        }
    }

    const changeSearchTerm = (search_key : string) => {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key))
    }

    const changeCurrentPage = (pageNo : number) => {
        dispatch(setCurrentPage(pageNo))
    }

    const changeRowsPerPage = (rows_perPage : number) => {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rows_perPage))
    }

    const changeFilterStatus = (status : string) => {
        dispatch(setFilterStatus(status));
    }

    return {
        getRefreeList,
        getRefreesByFilter,
        createReferee,
        deleteReferee,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
        changeFilterStatus
    }
}

export default useRefreeAction;