import { Avatar, Box, Divider, MenuItem, MenuList, Typography } from "@mui/material";
import React, { } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardTeamDataType } from "../../../types/dashboard";
import { getEncodedURL } from "../../../utils/imageUrl";

function TableHeader(): React.ReactElement {

    return (
        <>
            <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 6fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: 4 }}>
                <Typography variant="caption" textAlign={'center'}>#</Typography>
                <Typography variant="caption">Team</Typography>
                <Typography variant="caption" textAlign={'center'}>MP</Typography>
                <Typography variant="caption" textAlign={'center'}>W</Typography>
                <Typography variant="caption" textAlign={'center'}>D</Typography>
                <Typography variant="caption" textAlign={'center'}>L</Typography>
                <Typography variant="caption" textAlign={'center'}>GD</Typography>
                <Typography variant="caption" textAlign={'center'}>PTS</Typography>
            </Box>
            <Divider sx={{ mb: 0 }} />
        </>
    )
}


function ResultTable({ data }: { data: DashboardTeamDataType[] }): React.ReactElement {

    return (
        <>
            <TableHeader />
            <MenuList disablePadding>
                {
                    data.map((d, idx) => {
                        return (
                            <MenuItem key={idx} disableGutters sx={{ m: 0 }}>
                                <TableRow
                                    _id={d._id}
                                    order={idx + 1}
                                    name={d.name}
                                    logo={d.logo}
                                    match_played={d.match_played}
                                    win={d.win}
                                    draw={d.draw}
                                    lose={d.lose}
                                    goal={d.goal}
                                    points={d.points}
                                />
                            </MenuItem>
                        )
                    })
                }
            </MenuList>
        </>
    )
}

function TableRow(data: DashboardTeamDataType): React.ReactElement {
    const navigate = useNavigate();

    return (
        <Box component={'div'} onClick={() => navigate(`/teams/${data._id}`, {state : {name : data.name}})} sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 6fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: 4 }} >
            <Typography variant="body1" textAlign={'center'}>{data.order}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                <Avatar alt={data.name} src={data?.logo ? getEncodedURL(data.logo, 30, 30, 'cover') : ''} sx={{ width: 30, height: 30 }} />
                <Typography variant="body1">{data.name}</Typography>
            </Box>
            <Typography variant="body1" textAlign={'center'}>{data.match_played}</Typography>
            <Typography variant="body1" textAlign={'center'}>{data.win}</Typography>
            <Typography variant="body1" textAlign={'center'}>{data.draw}</Typography>
            <Typography variant="body1" textAlign={'center'}>{data.lose}</Typography>
            <Typography variant="body1" textAlign={'center'}>{data.goal}</Typography>
            <Typography variant="body1" textAlign={'center'}>{data.points}</Typography>
        </Box>
    )
}

export default ResultTable;