import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DefaultPagationInitailType } from "../../types/redux";
import { RefreeDataType } from "../../types/refree";

type RefreePayloadAction = PayloadAction<RefreeDataType[]>

const initialState : DefaultPagationInitailType<RefreeDataType> = {
    data : [],
    
    totalRows: 0,
    rowsPerPage: 5,
    currentPage: 0,
    searchTerm: '',

    isLoading: false,
    isError: false,
    errorMessage: '',
    action: {
        isError: false,
        isLoading: false,
        errorMessage: '',
    }
}

const refreeSlice = createSlice({
    name : 'refree',
    initialState,
    reducers: {
        setData : (state, action : RefreePayloadAction) => {
            state.data = action.payload
        },
        
        removeData : (state, action : RefreePayloadAction) => {
            state.data = action.payload
        },

        setTotalRows : (state, action: PayloadAction<number>) => {
            state.totalRows = action.payload
        },
        
        setCurrentPage : (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },

        setRowsPerPage : (state, action : PayloadAction<number>) => {
            state.rowsPerPage = action.payload
        },

        setSearchTerm : (state , action : PayloadAction<string>) => {
            state.searchTerm = action.payload
        },

        setFilterStatus : (state, action : PayloadAction<string>) => {
            if(state.filters && state.filters?.status) {
                state.filters.status = action.payload
            }
        },

        submittingData : (state) => {
            if(state.action) {
                state.action.isLoading = true;
                state.action.isError = false;
                state.action.errorMessage = ''
            }
        },

        submitDataSuccess : (state) => {
            if(state.action) {
                state.action.isLoading = false;
                state.action.isError = false;
            }
        },

        submitDataFail : (state, action : PayloadAction<string>) => {
            if(state.action) {
                state.action.isError = true;
                state.action.isLoading = false;
                state.action.errorMessage = action.payload
            }
        },

        submittingCancel : (state) => {
            if(state.action) {
                state.action.isError = false;
                state.action.isLoading = false;
                state.action.errorMessage = ''
            }
        },

        fetchingData : (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess : (state, action : PayloadAction<{data? : RefreeDataType[], total_rows?: number}>) => {
            if(action.payload.data) {
                state.data = action.payload.data;
              }
              if(action.payload.total_rows){
                state.totalRows = action.payload.total_rows
              }
              state.isLoading = false;
              state.isError = false;
        },

        fetchDataFail : (state, action : PayloadAction<string>) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        }
    }
})

export const {
    setData,
    removeData,
    setTotalRows,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setFilterStatus,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    submittingCancel,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail
} = refreeSlice.actions;

export default refreeSlice.reducer;