import {
      setNew,
      removeNew
} from "./_reducer";
import { useAppDispatch } from "../../hooks/useRedux";

const useNewRowsActions = () => {
      const dispatch = useAppDispatch();


      function addNewRow(id : string | number) {
            dispatch(setNew(id));
      }

      function removeNewRow(id : string | number) {
            dispatch(removeNew(id))
      }

      return {
            addNewRow,
            removeNewRow,
      }
}

export default useNewRowsActions;