const env = process.env.REACT_APP_ENV;
const s3_bucket = env == 'production' ? "ufl-images" :
    env == 'development' ? "ufl-images-test" :
        env == 'staging' ? "ufl-images-test" :
            "ufl-images-test";
 
export const getEncodedURL = (key: string, width: number, height: number, fit: string = 'fit') => {
 
    const jsonStr = JSON.stringify({
        bucket: s3_bucket,
        key: key,
        edits: {
            resize: {
                width: width,
                height: height,
                fit: fit
            }
        }
    })
    
    const encodedJsonStr = btoa(jsonStr)
    
    const url: string =  "https://img.ufl.caringcodecreation.com/" + encodedJsonStr
 
    return url
}