import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

type TimePickerControllerType = {
    name: string;
    control: any;
    label: string;
    defaultValue? : string
    errmsg: string | undefined;
    onChange?: (e: Dayjs | null) => void;
    placeholder : string
};

const TimePickerController = (props: TimePickerControllerType) => {
    const { name, control, errmsg, placeholder } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
                name={name}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                    <TimePicker
                        {...field}
                        label={props.label}
                        value={dayjs(field.value) ?? undefined}
                        format="hh:mm A"
                        slotProps={{
                            textField: {
                                helperText: errmsg ?? '',
                                error: Boolean(errmsg),
                                size: 'small',
                                placeholder: placeholder
                            }
                        }}
                        onChange={(v) => {
                            props.onChange ? 
                            field.onChange(props.onChange(v)) 
                            : 
                            field.onChange(v)
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default TimePickerController;