import React from 'react';
import { Box, Button, Card, Collapse, Paper, Typography } from '@mui/material';
import LoadingBox from '../Box/LoadingBox';
import ErrorBox from '../Box/ErrorBox';
import LoadingButton from '../Buttons/LoadingButton';
import WarningIcon from '@mui/icons-material/Warning';

const style = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: 220,
      width: '100%',
      marginBottom: 30,
}

/**
 * # SessionBox
 * @param {*} width 
 * @param {*} isLoading
 * @param {Boolean} isError
 * @param {Function} onRetry
 * @param {*} height
 * @param {*} disableHeader
 * @param {*} title
 * @returns SessionBox
 */

interface SessionBoxProps {
      width?: string
      isLoading?: boolean
      isError?: boolean
      errorMessage?: string
      onRetry?: Function
      height?: string
      disableHeader?: boolean
      title?: string,
      children: React.ReactNode,
      onSave?: Function,
      onDiscard?: Function,
      isShowWarnning?: boolean
}

const SessionBox = ({ children, height, isLoading, isError, errorMessage = '', onRetry, onSave, onDiscard, isShowWarnning }: SessionBoxProps) => {
      return (
            <>
                  {
                        isShowWarnning &&
                        <Collapse in={isShowWarnning} >
                              <Paper elevation={0} sx={{ px: 4, mb: 2, width: '100%', backgroundColor: '#F16E00', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                          <WarningIcon sx={{ mr: 4, color: 'white' }} />
                                          <Typography variant={'h6'} sx={{ m: 0, color: 'white' }}>You have unsaved changes!</Typography>
                                    </Box>
                                    <Box sx={{ gap: 5, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>

                                          <Button onClick={() => { typeof onDiscard === 'function' && onDiscard() }} variant="outlined" color="primary">
                                                Cancel
                                          </Button>

                                          <LoadingButton
                                                isLoading={false}
                                                label="save"
                                                onClick={() => { typeof onSave === 'function' && onSave() }}
                                                loadingIconSize={20}
                                          />
                                    </Box>
                              </Paper>
                        </Collapse>
                  }
                  <Card id="SessionBox"
                        sx={{ ...style, backgroundColor: (isLoading || isError) ? 'transparent' : 'default', marginBottom: '40px' }}
                        style={{ height: height ?? (isLoading || isError) ? '90%' : '' }}
                        elevation={1}
                  >
                        {
                              isLoading ? <LoadingBox /> :
                                    isError ? <ErrorBox errorMsg={errorMessage} onError={() => { typeof onRetry === 'function' && onRetry() }} /> :
                                          children

                        }
                  </Card >
            </>
      );
};

export default SessionBox;
