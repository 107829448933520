import { useAppSelector } from "../../hooks/useRedux";
import useRefreeAction from "./_action"

export const useRefree = () => {
    const {
        getRefreeList,
        getRefreesByFilter,
        createReferee,
        deleteReferee,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeFilterStatus,
    } = useRefreeAction();

    const data = useAppSelector(state => state.refree.data);
    const totalRows = useAppSelector(state => state.refree.totalRows);
    const rowsPerPage = useAppSelector(state => state.refree.rowsPerPage);
    const currentPage = useAppSelector(state => state.refree.currentPage);
    const searchTerm = useAppSelector(state => state.refree.searchTerm);
    const isLoading = useAppSelector(state => state.refree.isLoading)
    const isError = useAppSelector(state => state.refree.isError)
    const errorMessage = useAppSelector(state => state.refree.errorMessage);
    const status = useAppSelector(state => state.refree.filters?.status);
    const action = useAppSelector(state => state.refree.action);

    return {
        getRefreeList,
        getRefreesByFilter,
        createReferee,
        deleteReferee,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeFilterStatus,


        data,
        totalRows,
        rowsPerPage,
        currentPage,
        searchTerm,
        isLoading,
        isError,
        errorMessage,
        status,
        action
    }
}

export {default as refreeReducer} from "./_reducer";

