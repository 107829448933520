import { AxiosError } from "axios";

export const getErrorMessageResponse = (error : unknown) : string => {
   if(isAxiosError(error)) {
     return extractAxiosErrorMessage(error);
   }

   if(error instanceof Error) {
     return error.message;
   }

   if (typeof error === 'object' && error !== null && 'message' in error) {
        return String((error as { message: string }).message);
   }

   if(typeof error === "string") {
      return error;
   }

   return "Something went wrong!";
}

const isAxiosError = (error : unknown) : error is AxiosError => {
    return (error as AxiosError).isAxiosError !== undefined;
}

const extractAxiosErrorMessage = (error : AxiosError) : string => {
    if(error.response && error.response.data) {
        const data = error.response.data as { errors: {message? : string} }
        if(data.errors && data.errors?.message) {
            return data.errors.message
        }
    }

    return error.message
}