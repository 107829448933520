import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box } from "@mui/system";

const color = {
    Success: '#2AF818',
    Warning: '#ffc107',
    Error: '#dc3545'
}

type DialogType = {
    isOpen : boolean;
    type : 'warning' | 'error' | 'success';
    title : string;
    description : string;
    onConfirm : (() => void) | null
}

type ShowConfirmDialogType = {
    type: 'success' | 'error' | 'warning';
    title: string;
    description: string;
    handleConfirm: () => void;
}

const useConfirmDialog = () => {
    const [dialog, setDialog] = useState<DialogType>({
        isOpen: false,
        type: 'warning',
        title: '',
        description: '',
        onConfirm: null
    });

    useEffect(() => {
        /**
         * clean up after dialog close
         */
        return () => {
            dialog.isOpen && clearDialog();
        }
    }, [dialog.isOpen]);

    const handleClose = () => {
        setDialog(prevState => ({ ...prevState, isOpen: false }));
    }

    const clearDialog = () => {
        setDialog(prevState => {
            return {
                ...prevState,
                title: '',
                description: '',
                onConfirm: null
            }
        });
    }

    /**
     * ## Show Confirm Dialog Box
     * @param {String} type success || error || warning
     * @param {String} title Title of the dialog
     * @param {String} description Short description below the title
     * @param {Function} handleConfirm Callback function to execute on click confirm button
     */

    const showConfirmDialog = ({ type, title, description, handleConfirm } : ShowConfirmDialogType) => {
        setDialog({
            isOpen: true,
            type,
            title,
            description,
            onConfirm: handleConfirm
        });
    }


    const ConfirmDialog = () => {
        const currentColor = dialog.type == "error" ? color.Error :
            dialog.type == 'warning' ? color.Warning :
                color.Success;
        const CurrentIcon = dialog.type == 'error' ? ErrorOutlineIcon :
            dialog.type == 'warning' ? WarningAmberIcon :
                VerifiedIcon;

        return (
            <Dialog open={dialog.isOpen}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CurrentIcon sx={{ fontSize: 150, padding: '20px', color: currentColor }} />
                    <Typography sx={{ typography: 'h1', textAlign: 'center' }}>{dialog.title}</Typography>
                    <Typography sx={{ typography: 'h6', textAlign: 'center' }}>{dialog.description}</Typography>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: 5 }}>
                        { dialog.type == 'warning' && (<Button variant="outlined" onClick={handleClose}>Cancel</Button>) }
                        <Button 
                            variant="contained" 
                            onClick={() => {
                                dialog.onConfirm && dialog.onConfirm();
                                handleClose();
                            }}
                        >
                            { dialog.type == 'warning' ? 'Confirm': 'Okay' }
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return {
        showConfirmDialog,
        ConfirmDialog
    }
}

export default useConfirmDialog;