import useNewRowActions from './_action';
import { useAppSelector } from "../../hooks/useRedux";

export const useNewRows = () => {
      const {
            addNewRow,
            removeNewRow,
      } = useNewRowActions();

      const newRows = useAppSelector((state) => state.new_rows.data);

      return {
            //state
            newRows,
            //actions
            addNewRow,
            removeNewRow
      }
}

export { default as newRowReducer } from './_reducer';