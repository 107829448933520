import apiUrl from "../../configs/_apiUrl";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { PlayerFormModifiedValues } from "../../pages/team/_createPlayer";
import { TeamFormValues } from "../../pages/team/_detail";
import { useNoti } from "../../providers";
import { MatchDataType } from "../../types/match";
import { PlayerDataType } from "../../types/player";
import { TeamDataType } from "../../types/team";
import ApiRequest from "../../utils/apiRequest";
import { getErrorMessageResponse } from "../../utils/response";
import { 
    fetchingData, fetchDataFail, fetchDataSuccess, submittingData, submitDataFail, submitDataSuccess, setRowsPerPage, setCurrentPage, setSearchTerm, 
    fetchingTeamUnderPlayers, fetchTeamUnderPlayersFail, fetchTeamUnderPlayersSuccess, submittingPlayerData, submitPlayerDataFail, submitPlayerDataSuccess, setPlayerRowsPerPage, setPlayerCurrentPage, setPlayerSearchTerm, 
    fetchingTeamUnderUpcomingMatch, fetchTeamUnderUpcomingMatchFail, fetchTeamUnderUpcomingMatchSuccess, setUpcomingMatchRowsPerPage, setUpcomingMatchCurrentPage, 
    fetchingTeamUnderResultMatch, fetchTeamUnderResultMatchFail, fetchTeamUnderResultMatchSuccess, setResultMatchRowsPerPage, setResultMatchCurrentPage
} from "./_reducer";

const useTeamAction = () => {
    const { showNoti } = useNoti();
    const dispatch = useAppDispatch();

    const searchTerm = useAppSelector(state => state.team.searchTerm);
    const currentPage = useAppSelector(state => state.team.currentPage);
    const rowsPerPage = useAppSelector(state => state.team.rowsPerPage);

    const player_currentPage = useAppSelector(state => state.team?.player?.currentPage);
    const player_searchTerm = useAppSelector(state => state.team?.player?.searchTerm);
    const player_rowsPerPage = useAppSelector(state => state.team?.player?.rowsPerPage);
    const member_status = useAppSelector(state => state.team?.player?.filters?.status);


    const upcomingMatch_currentPage = useAppSelector(state => state.team?.upcomingMatch?.currentPage);
    const upcomingMatch_rowsPerPage = useAppSelector(state => state.team?.upcomingMatch?.rowsPerPage);

    const resultMatch_currentPage = useAppSelector(state => state.team?.resultMatch?.currentPage);
    const resultMatch_rowsPerPage = useAppSelector(state => state.team?.resultMatch?.rowsPerPage);
    

    const getTeamByFilter = async () => {
        dispatch(fetchingData());
        const url = `${apiUrl.TEAM}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`

       try {
        const res = await ApiRequest.get(url);
        const data : TeamDataType[] = res.data.payload.data;
        const metadata = res.data.payload.metadata;
        dispatch(fetchDataSuccess({data, total_rows: metadata.total_rows}));
       } catch (error : unknown) {
        console.error(error);
        const msg = getErrorMessageResponse(error);
        dispatch(fetchDataFail(msg))
       }
    }

    const getTeamDetail = async (team_id : string, image?: string, type? : string) => {
        dispatch(fetchingData());
        const url = `${apiUrl.TEAM}/${team_id}`;

        try {
            const res = await ApiRequest.get(url);
            const data : TeamDataType = res.data.payload.data;
            dispatch(fetchDataSuccess({team: data}));
            return data;
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg))
            return Promise.reject(msg)
        }
    }

    const teamUpdate = async (payloadData : TeamFormValues, team_id : string) => {
        dispatch(submittingData());
        const url = `${apiUrl.TEAM}/${team_id}`;
        
        try {
            await ApiRequest.put(url, payloadData);
            dispatch(submitDataSuccess());
            await getTeamDetail(team_id);
            showNoti("Team data is updated successfully.", "success")
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error");
            return Promise.reject(msg);
        }
    }

    const uploadTeamImage = async (formData : FormData, team_id : string, type: string) => {
        dispatch(submittingData());
        const url = `${apiUrl.TEAM}/upload_image`;
        try {
            await ApiRequest.post(url, {
                type : formData.get('type'),
                file : formData.get('file'),
                id : team_id
            }, {
                headers: {
                    'Content-Type':'multipart/form-data'
                }
            });
            dispatch(submitDataSuccess());
            showNoti(`Team ${type === 'logo' ? "logo" : 'banner'} is successfully uploaded.`, 'success');
            await getTeamDetail(team_id);
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error");
            return Promise.reject(msg);
        }
    }

    const createTeam = async (payloadData : TeamFormValues) => {
        dispatch(fetchingData());
        const url = `${apiUrl.TEAM}`;
        
        try {
            const res = await ApiRequest.post(url, payloadData);
            const data : TeamDataType = res.data.payload.data;
            dispatch(fetchDataSuccess({}));
            showNoti('Player data is edited successfully!', 'success');
            getTeamByFilter();
            return data;
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg));
            showNoti(msg, "error");
            return Promise.reject(msg);
        }
    }

    const getPlayersUnderTeam  = async (team_id : string) => {
       dispatch(fetchingTeamUnderPlayers());
       const url = `${apiUrl.PLAYER}?search_term=${player_searchTerm}&page_no=${player_currentPage + 1}&page_size=${player_rowsPerPage}&filters[team_id]=${team_id}`;
       try {
        const res = await ApiRequest.get(url);
        const data : PlayerDataType[] = res.data.payload.data;
        const metadata = res.data.payload.metadata;
        dispatch(fetchTeamUnderPlayersSuccess({data, total_rows: metadata.total_rows})) 
    } catch (error) {
        console.error(error);
        const msg = getErrorMessageResponse(error);
        dispatch(fetchTeamUnderPlayersFail(msg))
       }
    }

    const createPlayerUnderTeam = async (player : PlayerFormModifiedValues) => {
        dispatch(submittingPlayerData());
        const url = `${apiUrl.PLAYER}`;

        try {
            await ApiRequest.post(url, player);
            dispatch(submitPlayerDataSuccess());
            showNoti("Player has been successfully created.", "success");
            getPlayersUnderTeam(player.team_id);
        } catch (error : any) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitPlayerDataFail(msg));
            // showNoti(msg, "error");
            return Promise.reject(error?.response?.data?.errors ?? error);
        }
    }

    const removePlayerUnderTeam = async (id : string) => {
        dispatch(submittingData());
        const url = `${apiUrl.PLAYER}/${id}/remove`;

        try {
            await ApiRequest.patch(url);
            dispatch(submitDataSuccess());
            showNoti("Player removed successfully", "success")
        } catch (error : unknown) {
            const msg = getErrorMessageResponse(error);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error")
        }
    }

    const getUpcomingMatchUnderTeam  = async (team_id : string) => {
        dispatch(fetchingTeamUnderUpcomingMatch());
        const url = `${apiUrl.TEAM}/${team_id}/related_match?page_no=${upcomingMatch_currentPage + 1}&page_size=${upcomingMatch_rowsPerPage}&status=schedule`;
        try {
         const res = await ApiRequest.get(url);
         const data : MatchDataType[] = res.data.payload.data;
         const metadata = res.data.payload.metadata;
         dispatch(fetchTeamUnderUpcomingMatchSuccess({data, total_rows: metadata.total_rows})) 
     } catch (error) {
         console.error(error);
         const msg = getErrorMessageResponse(error);
         dispatch(fetchTeamUnderUpcomingMatchFail(msg))
        }
     }

     const getResultMatchUnderTeam  = async (team_id : string) => {
        dispatch(fetchingTeamUnderResultMatch());
        const url = `${apiUrl.TEAM}/${team_id}/related_match/?&page_no=${resultMatch_currentPage + 1}&page_size=${resultMatch_rowsPerPage}&status=completed`;
        try {
         const res = await ApiRequest.get(url);
         const data : MatchDataType[] = res.data.payload.data;
         const metadata = res.data.payload.metadata;
         dispatch(fetchTeamUnderResultMatchSuccess({data, total_rows: metadata.total_rows})) 
     } catch (error) {
         console.error(error);
         const msg = getErrorMessageResponse(error);
         dispatch(fetchTeamUnderResultMatchFail(msg))
        }
     }

    const changeSearchTerm = (search_key : string) => {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key))
    }

    const changeCurrentPage = (pageNo : number) => {
        dispatch(setCurrentPage(pageNo))
    }

    const changeRowsPerPage = (rows_perPage : number) => {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rows_perPage))
    }

    const changePlayerSearchTerm = (search_key : string) => {
        dispatch(setPlayerCurrentPage(0));
        dispatch(setPlayerSearchTerm(search_key))
    }

    const changePlayerCurrentPage = (pageNo : number) => {
        dispatch(setPlayerCurrentPage(pageNo))
    }

    const changePlayerRowsPerPage = (rows_perPage : number) => {
        dispatch(setPlayerCurrentPage(0));
        dispatch(setPlayerRowsPerPage(rows_perPage))
    }

    const changeUpcomingMatchCurrentPage = (pageNo : number) => {
        dispatch(setUpcomingMatchCurrentPage(pageNo))
    }

    const changeUpcomingMatchRowsPerPage = (upcomingMatch_rowsPerPage : number) => {
        dispatch(setUpcomingMatchCurrentPage(0));
        dispatch(setUpcomingMatchRowsPerPage(upcomingMatch_rowsPerPage))
    }

    const changeResultMatchCurrentPage = (pageNo : number) => {
        dispatch(setResultMatchCurrentPage(pageNo))
    }

    const changeResultMatchRowsPerPage = (resultMatch_rowsPerPage : number) => {
        dispatch(setResultMatchCurrentPage(0));
        dispatch(setResultMatchRowsPerPage(resultMatch_rowsPerPage))
    }

    return  {
        getTeamByFilter,
        getTeamDetail,
        teamUpdate,
        createTeam,
        getPlayersUnderTeam,
        uploadTeamImage,
        createPlayerUnderTeam,
        removePlayerUnderTeam,
        changeCurrentPage,
        changeSearchTerm,
        changeRowsPerPage,
        changePlayerSearchTerm,
        changePlayerCurrentPage,
        changePlayerRowsPerPage,

        getUpcomingMatchUnderTeam,
        changeUpcomingMatchCurrentPage,
        changeUpcomingMatchRowsPerPage,

        getResultMatchUnderTeam,
        changeResultMatchCurrentPage,
        changeResultMatchRowsPerPage
    }
}


export default useTeamAction;