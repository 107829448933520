import React, { useState } from "react";
import { Close, Search } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from "@mui/material";

/**
 * # Search Box
 * @param {Event} changedSearchTerm 
 * @param {String} initVal
 * @param {String} placeholder
 * @param {Object} sx
 * @returns 
 */

type SearchBoxPropsType = {
      initVal?: string,
      placeholder?: string,
      sx?: OutlinedInputProps["sx"],
      changedSearchTerm: (searchTerm : string) => void,
      reset?: boolean
}

const SearchBox = ({initVal, placeholder, sx, changedSearchTerm, reset=false} : SearchBoxPropsType) => {
      const [searchTerm, setSearchTerm] = useState(initVal ? initVal : '');

      React.useEffect(() => {
            if (searchTerm === '') {
                  changedSearchTerm('')
            }
      }, [searchTerm]);

      React.useEffect(() => {
            setSearchTerm('');
      }, [reset]);

      const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                  changedSearchTerm(searchTerm)
            }
      }

      return (
            <OutlinedInput
                  id='searchBox'
                  size='small'
                  placeholder={placeholder}
                  sx={{ ...sx }}
                  value={searchTerm}
                  onKeyDown={keyPress}
                  onChange={(e) => { setSearchTerm(e.target.value); }}
                  endAdornment={
                        <InputAdornment position="end">
                              <Box sx={{ flexWrap: 1 }}>
                                    {
                                          searchTerm !== undefined && searchTerm !== '' && (
                                                <IconButton
                                                      sx={{padding: '2px'}}
                                                      onClick={() => setSearchTerm('')}
                                                >
                                                      <Close fontSize="small"/>
                                                </IconButton>
                                          )
                                    }
                                    <IconButton
                                          edge="end"
                                          onClick={() => changedSearchTerm(searchTerm)}
                                    >
                                          <Search />
                                    </IconButton>
                              </Box>

                        </InputAdornment>}
            />
      )
}

export default SearchBox;