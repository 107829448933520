import React from 'react';
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

type NotiType = "error" | "success";

const useNoti = () => {
      const { enqueueSnackbar, closeSnackbar } = useSnackbar();

      function showNoti(message: string, type: NotiType) {
            const action = () => {
                  return (
                        <IconButton onClick={() => closeSnackbar(key)} size='small'>
                              <Close sx={{ color: 'white', width: 20, height: 20 }} />
                        </IconButton>
                  )
            }
            const key = enqueueSnackbar(message, { action, variant: type })
      }

      return {
            showNoti
      }
};

export default useNoti;