import React from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh'
}

const GlobalLoading = () => {
      return (
            <Box sx={style}>
                  <CircularProgress />
            </Box>
      );
}

export default GlobalLoading;