import React from 'react';
import { Box } from '@mui/material';
import ChildrenType from '../../types/children';


/**
 * # SessionBox
 * @param {*} children  
 */

type SessionContentPropsType = ChildrenType & {
      noPaddingTop? :boolean;
}

const SessionBoxContent : React.FC<SessionContentPropsType> = ({ children, noPaddingTop }) => {
      return (
            <Box id="SessionBoxContent" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  padding: noPaddingTop ? '0px 30px 30px 30px' : '30px 30px 30px 30px',
                  backgroundColor: 'background',
                  gap: 4
            }} >
                  {children}
            </Box>
      );
};

export default SessionBoxContent;
