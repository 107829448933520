import { useDispatch } from 'react-redux';
import { changeThemeMode, openDrawerRedu, toggleSideBar } from './_reducer';

const useThemeSettingActions = () => {
      const dispatch = useDispatch();

      function changeMode(): void {
            dispatch(changeThemeMode());
      }
      function toggleLeftSideBar(bool: boolean): void {
            dispatch(toggleSideBar(bool));
      }
      function openDrawer(bool: boolean): void {
            dispatch(openDrawerRedu(bool))
      }

      return {
            changeMode,
            toggleLeftSideBar,
            openDrawer
      };

};

export default useThemeSettingActions;