import apiUrl from "../../configs/_apiUrl";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { useNoti } from "../../providers";
import { MatchDataType } from "../../types/match";
import ApiRequest from "../../utils/apiRequest";
import { getErrorMessageResponse } from "../../utils/response";
import { fetchDataFail, fetchDataSuccess, fetchingData, setCurrentPage, setRowsPerPage, setSearchTerm, submitDataFail, submitDataSuccess, submittingData, setStatusFilter } from "./_reducer";

const useMatchAction = () => {
    const { showNoti } = useNoti();
    const dispatch = useAppDispatch();

    const searchTerm = useAppSelector(state => state.match.searchTerm);
    const statusFilter = useAppSelector(state => state.match.status);
    const currentPage = useAppSelector(state => state.match.currentPage);
    const rowsPerPage = useAppSelector(state => state.match.rowsPerPage);

    const getHighLightDetail = async (id: any) => {
        dispatch(fetchingData());
        const url = `${apiUrl.Match}/${id}`
       try {
        const res = await ApiRequest.get(url);
        const data : MatchDataType[] = res.data.payload.data;
        dispatch(fetchDataSuccess({}))
        return data;
       } catch (error : unknown) {
        const msg = getErrorMessageResponse(error);
        dispatch(fetchDataFail(msg));
        return Promise.reject(msg)
       }
    }

    const getMatchDetail = async (match_id : string) => {
        dispatch(fetchingData());
        const url = `${apiUrl.Match}/${match_id}`;

        try {
            const res = await ApiRequest.get(url);
            const data : MatchDataType = res.data.payload.data;
            dispatch(fetchDataSuccess({match: data}));
            return data;
        } catch (error) {
            const msg = getErrorMessageResponse(error);
            dispatch(fetchDataFail(msg))
            return Promise.reject(msg)
        }
    }
   
    const getMatchByFilter = async () => {
        dispatch(fetchingData());
        let url = `${apiUrl.Match}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`;
        if(statusFilter) {
            url += `&status=${statusFilter}`;
        }

       try {
        const res = await ApiRequest.get(url);
        const data : MatchDataType[] = res.data.payload.data;
        const metadata = res.data.payload.metadata;
        dispatch(fetchDataSuccess({data, total_rows: metadata.total_rows}));
       } catch (error : unknown) {
        console.error(error);
        const msg = getErrorMessageResponse(error);
        dispatch(fetchDataFail(msg))
       }
    }

    async function uploadHighLightImage(fileData: FormData, _id: any) {
      dispatch(submittingData());
      const url = `${apiUrl.Match}/${_id}/upload_image`;
  
      try {
          const res = await ApiRequest.post(url, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
            console.log("fileDataInAction", fileData)
            const data = res?.data?.payload?.data;
            dispatch(submitDataSuccess());
            return data;
      } catch (err: any) {
          const errors: any = {};
          errors.data = err?.response?.data?.errors?.data;
          dispatch(submitDataFail(err.response.data.errors.message ?? "Uploading image failed!"));
          return Promise.reject(errors);
      }
  }

    async function deleteHighLightImage(imageUrl: string, _id: any) {
        dispatch(submittingData());

        const url = `${apiUrl.Match}/${_id}/remove_image`;
        
        const deleteData: any ={
            url: imageUrl,
        }
        try {
            const res = await ApiRequest.patch(url, deleteData);
            const data = res?.data?.payload?.data;
            dispatch(submitDataSuccess());
            return data;
        } catch (err: any) {
            const errors: any = {};
            errors.data = err?.response?.data?.errors?.data;
            dispatch(submitDataFail(err.response.data.errors.message ?? "Deleting image failed!"));
            return Promise.reject(errors);
        }
    }

    async function updateMatch(data: any) {
        dispatch(submittingData());
        const url = `${apiUrl.Match}/${data._id}`;

        const updateData = {
          date: data?.date,
          time: data?.time,
          week_num: data.week_num,
          stadium: data.stadium,
          referee_id: data.referee_id
        }
    
        try {
          const res = await ApiRequest.put(url, updateData);
          const order = res.data
          dispatch(submitDataSuccess())
          return order
        } catch (err: any) {
          const errors: any = {};
          errors.data = err?.response?.data?.errors?.data;
          dispatch(submitDataFail(err.response.data.errors.message ?? "updating match failed!"));
          return Promise.reject(errors);
        }
    }

    async function createMatchSchedule() {
        dispatch(submittingData());
        const url = `${apiUrl.Match}/create/schedule`;

        try {
            const res = await ApiRequest.post(url);
            dispatch(submitDataSuccess())
            showNoti('Match schedule is created successfully!', 'success');
            getMatchByFilter();
            return res
        } catch (err: any) {
            const msg = getErrorMessageResponse(err);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error")
            return Promise.reject(err);
        }
    }

    async function completeMatch(id: string) {
        dispatch(submittingData());
        const url = `${apiUrl.Match}/${id}/complete`;
        try {
            const res = await ApiRequest.patch(url);
            dispatch(submitDataSuccess());
            showNoti('Match is change to "Complete" successfully!', 'success');
            getMatchByFilter();
        } catch (err) {
            const msg = getErrorMessageResponse(err);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error")
            return Promise.reject(err);
        }
    }

    async function scheduleMatch(id: string) {
        dispatch(submittingData());
        const url = `${apiUrl.Match}/${id}/schedule`;
        try {
            const res = await ApiRequest.patch(url);
            dispatch(submitDataSuccess());
            showNoti('Match is change to "Schedule" successfully!', 'success');
            getMatchByFilter();
        } catch (err) {
            const msg = getErrorMessageResponse(err);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error")
            return Promise.reject(err);
        }
    }

    async function getMetaForCreateMatchSchedule() {
        dispatch(submittingData());
        const url = `${apiUrl.Match}/create_schedule/get_meta`;
        try {
            const res = await ApiRequest.get(url);
            const meta = res?.data?.payload?.data;
            dispatch(submitDataSuccess())
            return meta
        } catch (err: any) {
            const msg = getErrorMessageResponse(err);
            dispatch(submitDataFail(msg));
            showNoti(msg, "error")
            return Promise.reject(err);
        }
    }

    const changeSearchTerm = (search_key : string) => {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key))
    }

    const changeStatusFilter = (status : "completed"|"schedule"|"draft"|null) => {
        dispatch(setCurrentPage(0));
        dispatch(setStatusFilter(status));
    }

    const changeCurrentPage = (pageNo : number) => {
        dispatch(setCurrentPage(pageNo))
    }

    const changeRowsPerPage = (rows_perPage : number) => {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rows_perPage))
    }

    return  {
        getMatchDetail,
        getHighLightDetail,
        deleteHighLightImage,
        uploadHighLightImage,
        updateMatch,
        getMatchByFilter,
        changeCurrentPage,
        changeSearchTerm,
        changeRowsPerPage,
        createMatchSchedule,
        getMetaForCreateMatchSchedule,
        completeMatch,
        scheduleMatch,
        changeStatusFilter
    }
}


export default useMatchAction;