import { TextField } from '@mui/material';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';


type TextBoxProps = {
    id : string;
    label: string;
    instock? : string;
    value? : string;
    defaultValue? : string;
    placeholder? : string;
    errMsg? : string;
    borderColor? : string;
    onChange?: (e: any) => void;
    type?: string; 
    disabled?: boolean;
}

const TextBox : React.FC<TextBoxProps> = ({
    id,
    label,
    instock,
    value,
    defaultValue,
    placeholder = '',
    errMsg,
    borderColor,
    ...props
}) => {

      return (

            <Tooltip title={instock} placement="top">
            <TextField
                  id={id}
                  label={label}
                  value={value}
                  defaultValue={defaultValue}
                  placeholder={placeholder ?? ''}
                  autoComplete='off'
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(errMsg ?? '')}
                  helperText={errMsg ?? ''} 
                  {...props}
                  sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: borderColor,
                            },
                            '&:hover fieldset': {
                                borderColor: borderColor,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: borderColor,
                            },
                        },
                    }}
            />
            </Tooltip>
      )
}

export default TextBox;