import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type initialStateType = {
      mode: "light" | "dark",
      collapseSideBar: boolean,
      isOpenDrawer: boolean,
      width: string
}

const initialState: initialStateType = {
      mode: 'light',
      collapseSideBar: true,
      isOpenDrawer: false,
      width: '64px',
}

const themeSettingSlice = createSlice({
      name: 'theme',
      initialState,
      reducers: {
            changeThemeMode: (state) => {
                  state.mode = state.mode === 'light' ? 'dark' : 'light';
            },
            toggleSideBar: (state, action: PayloadAction<boolean>) => {
                  state.collapseSideBar = action.payload;
            },
            openDrawerRedu: (state, action: PayloadAction<boolean>) => {
                  state.isOpenDrawer = action.payload;
                  state.width = action.payload ? '200px' : '64px';
            }
      },
});

export const { changeThemeMode, toggleSideBar, openDrawerRedu } = themeSettingSlice.actions;

export default themeSettingSlice.reducer;