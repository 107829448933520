import React, { } from "react";
import { Avatar, Box, Divider, MenuItem, MenuList, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DashboardTopScoreDataType } from "../../../types/dashboard";
import { getEncodedURL } from "../../../utils/imageUrl";

function TopScorerTableHeader(): React.ReactElement {

    return (
        <>
            <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 6fr 1fr 1fr 1fr', columnGap: 4 }}>
                <Typography variant="caption" textAlign={'center'}>#</Typography>
                <Typography variant="caption">Name</Typography>
                <Typography variant="caption" textAlign={'center'}>Goal</Typography>
                <Typography variant="caption" textAlign={'center'}>Assist</Typography>
            </Box>
            <Divider sx={{ mb: 0 }} />
        </>
    )
}

function TopScorerTableRow(data: DashboardTopScoreDataType): React.ReactElement {
    const navigate = useNavigate();
    return (
        <Box component={'div'} onClick={() => navigate(`/players/${data?._id}`, {state: {name : data.name}})} sx={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 6fr 1fr 1fr 1fr', columnGap: 4 }} >
            <Typography variant="h6" textAlign={'center'}>{data.order}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                <Avatar alt={data.name} src={data?.player_profile_url ? getEncodedURL(data?.player_profile_url, 40, 40, 'cover') : ''} sx={{ width: 40, height: 40, mr: 4, backgroundColor: 'lightgray' }} />
                <Typography variant="h6">{data.name}</Typography>
            </Box>
            <Typography variant="h6" textAlign={'center'}>{data.goals ?? 0}</Typography>
            <Typography variant="h6" textAlign={'center'}>{data.assists ?? 0}</Typography>
        </Box>
    )
}

function TopScorerTable({ data }: { data: DashboardTopScoreDataType[] }): React.ReactElement {

    return (
        <>
            <TopScorerTableHeader />

            <MenuList disablePadding>
                {
                    data.map((d, idx) => {
                        return (
                            <MenuItem key={idx} disableGutters sx={{ m: 0, height: 60 }}>
                                <TopScorerTableRow
                                    _id={d._id}
                                    order={idx + 1}
                                    name={d.name}
                                    player_profile_url={d.player_profile_url}
                                    goals={d.goals}
                                    assists={d.assists}
                                />
                            </MenuItem>
                        )
                    })
                }
            </MenuList>

        </>
    )
}

export default TopScorerTable;