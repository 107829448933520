import React from "react";
import StyledIconButton from "./StyledIconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { useNoti } from "../../providers";

interface CopyIconButtonType {
    copyText: string | null,
    label?: string;
    children?: React.ReactNode;
    success?: string;
    fail? : string;
}

const CopyIconButton = ({ copyText, label, success, fail, children } : CopyIconButtonType) => {
    const {showNoti} = useNoti();
    const [isCopy, setIsCopy] = React.useState(false);

    const onClick = React.useCallback((e : React.MouseEvent) => {
        e.stopPropagation();
            if(copyText){
                !isCopy && window.navigator.clipboard.writeText(copyText)
                .then(() => {
                    showNoti(`${success ?? 'Successfully copied.'}`, 'success');
                    setIsCopy(true);
                })
                .catch((err) => {
                    console.error("Failed to copy: ", err);
                    showNoti(`${fail ?? 'Failed to copy'}`, 'error');
                    setIsCopy(false);
                    return;
                });
            }else {
                showNoti('No Id to copy', 'error');
                setIsCopy(false);
                return;
            }

        setTimeout(() => {
            setIsCopy(false); // after 3 seconds change previous copy state // match with noti timeout
        }, 3000);
    }, [copyText, isCopy]);

    return (
        <>
            <StyledIconButton onClick={onClick} label={isCopy ? 'Copied' : `${label ?? 'Copy'}`}>
                {isCopy ? <CheckIcon color="success"/> : children ? children : <ContentCopyIcon/>}
            </StyledIconButton>
        </>
    )
}

export default React.memo(CopyIconButton);