import { createSlice } from "@reduxjs/toolkit";

type InitailStateType = {
    data : string[]
}

const initialState : InitailStateType = {
    data : ['6422aeed667c0d4db1214f3b']
}

const newRowsSlice = createSlice({
      name: 'newRows',
      initialState,
      reducers: {
            setNew: (state, action) => {
                  state.data.push(action.payload);
            },
            removeNew: (state, action) => {
                  const index = state.data.indexOf(action.payload);
                  if (index > -1) { // only splice array when item is found
                        state.data.splice(index, 1); // 2nd parameter means remove one item only
                  }
            },
      }
});

export const {
      setNew,
      removeNew
} = newRowsSlice.actions;

export default newRowsSlice.reducer;