import React from 'react';
import { Box, } from '@mui/material';
import ChildrenType from '../../types/children';

const style = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0px',
      backgroundColor: 'background',
}

/**
 * # SessionBox
 * @param {*} children  
 */
const SessionBoxTabPanel = ({ children } : ChildrenType) => {
      return (
            <Box id="SessionBoxTabPanel" sx={{ width: '100%', p: 0, pt: 4, m: 0 }}>
                  <Box sx={style} >
                        {children}
                  </Box>
                  {/* <Divider /> */}
            </Box>

      );
};

export default SessionBoxTabPanel;
