import { useAppSelector } from "../../hooks/useRedux"
import { MatchEvent } from "../../types/matchEvent";
import useMatchEventActions from "./_action";

export const useMatchEvent = () => {

    const {
        fetchMatchEvents,
        createMatchEvent,
        deleteMatchEvent
    } = useMatchEventActions();

    const raw_data: MatchEvent[] = useAppSelector(state => state.matchEvent.data);
    const sorted_data: MatchEvent[] = raw_data.length > 0 ? [...raw_data].sort((a, b) => a.event_minute - b.event_minute) : [];
    const isLoading: boolean = useAppSelector(state => state.matchEvent.isLoading);
    const isError: boolean = useAppSelector(state => state.matchEvent.isError);
    const errMsg: string = useAppSelector(state => state.matchEvent.errorMessage);
    const action = useAppSelector(state => state.matchEvent.action);

    return {
        data: sorted_data,
        isLoading,
        isError,
        errMsg,
        action,
        fetchMatchEvents,
        createMatchEvent,
        deleteMatchEvent
    }
}


export {default as matchEventReducer} from "./_reducer"