import React from "react";
import logo from '../../assets/images/logo.png';
import logoLight from '../../assets/images/logo.png';
import { useTheme } from "@emotion/react";


const ThemeLogo = (props) => {
      const theme = useTheme();
      return (
            theme.palette.mode === 'dark'
                  ? <img src={logo} height={props.size} alt="logo1" onClick={() => props.onClick} />
                  : <img src={logoLight} height={props.size} alt="logo2" onClick={() => props.onClick} />
      )
};

export default ThemeLogo;
