import React from "react";
import { Button, ButtonProps, CircularProgress, Typography } from "@mui/material";


type Props = {
    isLoading: boolean,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    label: string,
    sx?: ButtonProps["sx"],
    isError?: boolean,
    errMsg?: string,
    loadingIconSize?: number,
    disabled?: boolean,
    variant?: "contained"|"outlined"|"text"
    action?: string;
}
const LoadingButton = (props: React.PropsWithChildren & Props) => {
      const variant = props.variant ?? 'contained';
      const [ showBtn, setShowBtn ] = React.useState(true);

      React.useEffect(() => {
            if(props.action){
                  setShowBtn(false);
            } else {
                  setShowBtn(true);
            }
      }, [props.action]);

      return (
                  showBtn ? 
                  props.isLoading
                  ? <Button sx={{...(props.sx ?? {}), opacity: 0.7}} variant={variant} >
                        <CircularProgress color="info" size={props.loadingIconSize ?? 30} sx={{mr: 2}}/> {props.label}
                    </Button>
                  : <>
                        <Button onClick={props.onClick} variant={variant} size={'medium'} sx={props.sx ?? {}} disabled={props.disabled ?? false}>
                              {props.children ? props.children : props.label}
                        </Button>
                        {props.isError && <Typography variant="body1" color={'error'}>{props.errMsg}</Typography>}
                    </>
                  : null
      )
};

export default LoadingButton;
