import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DefaultPagationInitailType } from "../../types/redux";
import { TeamDataType } from "../../types/team";
import { PlayerDataType } from "../../types/player";
import { MatchDataType } from "../../types/match";

type TeamPayloadAction = PayloadAction<TeamDataType[]>

const initialState : DefaultPagationInitailType<TeamDataType>  = {
    data: [],

    info : null,

    totalRows: 0,
    rowsPerPage: 5,
    currentPage: 0,
    searchTerm: '',

    isLoading: false,
    isError: false,
    errorMessage: '',
    player : {
        data : [],

        isLoading: false,
        isError: false,
        errorMessage: '',

        totalRows : 0,
        rowsPerPage : 5,
        currentPage: 0,
        searchTerm: '',
        filters : {
            status : 'active'
        },

        action: {
            isError: false,
            isLoading: false,
            errorMessage: ''
        }
    },
    upcomingMatch : {
        data : [],

        isLoading: false,
        isError: false,
        errorMessage: '',

        totalRows : 0,
        rowsPerPage : 5,
        currentPage: 0,
        filters : {
            status : 'schedule'
        },

        action: {
            isError: false,
            isLoading: false,
            errorMessage: ''
        }
    },
    resultMatch : {
        data : [],

        isLoading: false,
        isError: false,
        errorMessage: '',

        totalRows : 0,
        rowsPerPage : 5,
        currentPage: 0,
        filters : {
            status : 'completed'
        },

        action: {
            isError: false,
            isLoading: false,
            errorMessage: ''
        }
    },
    action: {
        isError: false,
        isLoading: false,
        errorMessage: ''
    }
}

export const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers : {
        setData : (state, action : TeamPayloadAction) => {
            state.data = action.payload
        },
        
        removeData : (state, action : TeamPayloadAction) => {
            state.data = action.payload
        },

        setTotalRows : (state, action: PayloadAction<number>) => {
            state.totalRows = action.payload
        },
        
        setCurrentPage : (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },

        setRowsPerPage : (state, action : PayloadAction<number>) => {
            state.rowsPerPage = action.payload
        },

        setSearchTerm : (state , action : PayloadAction<string>) => {
            state.searchTerm = action.payload
        },

        submittingData : (state) => {
            if(state.action) {
                state.action.isLoading = true;
                state.action.isError = false;
                state.action.errorMessage = ''
            }
        },

        submitDataSuccess : (state) => {
            if(state.action) {
                state.action.isLoading = false;
                state.action.isError = false;
            }
        },

        submitDataFail : (state, action : PayloadAction<string>) => {
            if(state.action) {
                state.action.isError = true;
                state.action.isLoading = false;
                state.action.errorMessage = action.payload
            }
        },

        submittingCancel : (state) => {
            if(state.action) {
                state.action.isError = false;
                state.action.isLoading = false;
                state.action.errorMessage = ''
            }
        },

        fetchingData : (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess : (state, action : PayloadAction<{data?: TeamDataType[], total_rows?: number, team?: TeamDataType}>) => {
            const {data, total_rows, team } = action.payload;
            if(data) {
            state.data = data;
            }
            if(total_rows){
            state.totalRows = total_rows
            }
            if(team){
                state.info = team;
            }
            state.isLoading = false;
            state.isError = false;
        },

        fetchDataFail : (state, action : PayloadAction<string>) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        },

        // =============== [ PLAYER ] ================ //

        setPlayerTotalRows : (state, action: PayloadAction<number>) => {
            state.player.totalRows = action.payload
        },
        
        setPlayerCurrentPage : (state, action: PayloadAction<number>) => {
            state.player.currentPage = action.payload
        },

        setPlayerRowsPerPage : (state, action : PayloadAction<number>) => {
            state.player.rowsPerPage = action.payload
        },

        setPlayerSearchTerm : (state , action : PayloadAction<string>) => {
            state.player.searchTerm = action.payload
        },

        setPlayerFilterStatus : (state, action : PayloadAction<string>) => {
            if(state.player?.filters && state.player?.filters?.status) {
                state.player.filters.status = action.payload
            }
        },

        fetchingTeamUnderPlayers : (state) => {
            state.player.isLoading = true;
            state.player.isError = false;
        },

        fetchTeamUnderPlayersSuccess : (state, action : PayloadAction<{data : PlayerDataType[], total_rows? : number}>) => {
            if(action.payload?.data) {
                state.player.data = action.payload.data;
            }
            if(action.payload?.total_rows) {
                state.player.totalRows = action.payload.total_rows
            }

            state.player.isLoading = false;
            state.player.isError = false;
        },

        fetchTeamUnderPlayersFail : (state, action : PayloadAction<string>) => {
            state.player.isLoading = false;
            state.player.isError = true;
            state.player.errorMessage = action.payload;
        },

        submittingPlayerData : (state) => {
            if(state.player?.action) {
                state.player.action.isLoading = true;
                state.player.action.isError = false;
                state.player.action.errorMessage = ''
            }
        },

        submitPlayerDataSuccess : (state) => {
            if(state.player?.action) {
                state.player.action.isLoading = false;
                state.player.action.isError = false;
            }
        },

        submitPlayerDataFail : (state, action: PayloadAction<string>) => {
            if(state.player?.action) {
                state.player.action.isError = true;
                state.player.action.isLoading = false;
                state.player.action.errorMessage = action.payload
            }
        },


        // =============== [ Upcoming Matches ] ================ //

        setUpcomingMatchTotalRows : (state, action: PayloadAction<number>) => {
            state.upcomingMatch.totalRows = action.payload
        },
        
        setUpcomingMatchCurrentPage : (state, action: PayloadAction<number>) => {
            state.upcomingMatch.currentPage = action.payload
        },

        setUpcomingMatchRowsPerPage : (state, action : PayloadAction<number>) => {
            state.upcomingMatch.rowsPerPage = action.payload
        },

        fetchingTeamUnderUpcomingMatch : (state) => {
            state.upcomingMatch.isLoading = true;
            state.upcomingMatch.isError = false;
        },

        fetchTeamUnderUpcomingMatchSuccess : (state, action : PayloadAction<{data : MatchDataType[], total_rows? : number}>) => {
            if(action.payload?.data) {
                state.upcomingMatch.data = action.payload.data;
            }
            if(action.payload?.total_rows) {
                state.upcomingMatch.totalRows = action.payload.total_rows
            }

            state.upcomingMatch.isLoading = false;
            state.upcomingMatch.isError = false;
        },

        fetchTeamUnderUpcomingMatchFail : (state, action : PayloadAction<string>) => {
            state.upcomingMatch.isLoading = false;
            state.upcomingMatch.isError = true;
            state.upcomingMatch.errorMessage = action.payload;
        },

        // =============== [ Result Matches ] ================ //

        setResultMatchTotalRows : (state, action: PayloadAction<number>) => {
            state.resultMatch.totalRows = action.payload
        },
        
        setResultMatchCurrentPage : (state, action: PayloadAction<number>) => {
            state.resultMatch.currentPage = action.payload
        },

        setResultMatchRowsPerPage : (state, action : PayloadAction<number>) => {
            state.resultMatch.rowsPerPage = action.payload
        },

        fetchingTeamUnderResultMatch : (state) => {
            state.resultMatch.isLoading = true;
            state.resultMatch.isError = false;
        },

        fetchTeamUnderResultMatchSuccess : (state, action : PayloadAction<{data : MatchDataType[], total_rows? : number}>) => {
            if(action.payload?.data) {
                state.resultMatch.data = action.payload.data;
            }
            if(action.payload?.total_rows) {
                state.resultMatch.totalRows = action.payload.total_rows
            }

            state.resultMatch.isLoading = false;
            state.resultMatch.isError = false;
        },

        fetchTeamUnderResultMatchFail : (state, action : PayloadAction<string>) => {
            state.resultMatch.isLoading = false;
            state.resultMatch.isError = true;
            state.resultMatch.errorMessage = action.payload;
        },
      
    }
})


export const {
    setData,
    removeData,
    setTotalRows,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setPlayerTotalRows,
    setPlayerCurrentPage,
    setPlayerRowsPerPage,
    setPlayerSearchTerm,
    setPlayerFilterStatus,
    setUpcomingMatchTotalRows,
    setUpcomingMatchCurrentPage,
    setUpcomingMatchRowsPerPage,
    setResultMatchTotalRows,
    setResultMatchCurrentPage,
    setResultMatchRowsPerPage,
    // setUpcomingMatchFilterStatus,

    submittingData,
    submitDataSuccess,
    submitDataFail,
    submittingCancel,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    fetchingTeamUnderUpcomingMatch,
    fetchTeamUnderUpcomingMatchSuccess,
    fetchTeamUnderUpcomingMatchFail,

    fetchTeamUnderPlayersFail,
    fetchTeamUnderPlayersSuccess,
    fetchingTeamUnderPlayers,
    submitPlayerDataFail,
    submitPlayerDataSuccess,
    submittingPlayerData,

    fetchTeamUnderResultMatchFail,
    fetchTeamUnderResultMatchSuccess,
    fetchingTeamUnderResultMatch,


} = teamSlice.actions;

export default teamSlice.reducer;