import { setAsMobile, setOpenDrawer } from './_reducer';
import { useAppDispatch } from '../../hooks/useRedux';

const useLayoutSettingActions = () => {
      const dispatch = useAppDispatch();

      function setAsMobileSize(bool: boolean) {
            dispatch(setAsMobile(bool));
      }
      function openDrawer(bool: boolean) {
            dispatch(setOpenDrawer(bool));
      }

      return {
            setAsMobileSize,
            openDrawer
      };

};

export default useLayoutSettingActions;