import React from 'react';
import { TextField, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type Props = {
    id: string,
    label?: string,
    errMsg?: any,
    placeholder?: string,
    onKeyDown?: any,
    error?: boolean
    helperText?: string
    inputRef?: any
    inputProps?: any
};

const PasswordField = (props: Props) => {
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    return (

        <TextField
            { ...props }
            id={props.id}
            label={props.label}
            fullWidth
            error={Boolean(props.errMsg ?? '')}
            helperText={(props.errMsg ?? '')}
            type={showPassword ? "text" : "password"}
            placeholder={props.placeholder ?? ''}   
            InputProps={{
                endAdornment: <InputAdornment position="end">
                      <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                      >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                </InputAdornment>,
                autoComplete: "off"
            }}
        />
    )
}

export default PasswordField;