import { useAppSelector } from "../../hooks/useRedux"
import useTeamAction from "./_action";

export const useMatch = () => {

    const {
        getMatchDetail,
        getHighLightDetail,
        deleteHighLightImage,
        uploadHighLightImage,
        updateMatch,
        getMatchByFilter,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        createMatchSchedule,
        getMetaForCreateMatchSchedule,
        completeMatch,
        scheduleMatch,
        changeStatusFilter
    } = useTeamAction();

    const data = useAppSelector(state => state.match.data);
    const totalRows = useAppSelector(state => state.match.totalRows);
    const rowsPerPage = useAppSelector(state => state.match.rowsPerPage);
    const currentPage = useAppSelector(state => state.match.currentPage);
    const searchTerm = useAppSelector(state => state.match.searchTerm);
    const statusFilter = useAppSelector(state => state.match.status);
    const isLoading = useAppSelector(state => state.match.isLoading)
    const isError = useAppSelector(state => state.match.isError)
    const errorMessage = useAppSelector(state => state.match.errorMessage)
    const action = useAppSelector(state => state.match.action)

    return {
        getMatchDetail,
        getHighLightDetail,
        deleteHighLightImage,
        uploadHighLightImage,
        updateMatch,
        getMatchByFilter,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        createMatchSchedule,
        getMetaForCreateMatchSchedule,
        completeMatch,
        scheduleMatch,
        changeStatusFilter,

        data,
        totalRows,
        rowsPerPage,
        currentPage,
        searchTerm,
        statusFilter,
        isLoading,
        isError,
        errorMessage,
        action
    }
}


export {default as matchReducer} from "./_reducer"