import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AccountMenuPopper, ChangeThemeButton } from '../components';
import { useUser } from '../features';

const TopNavigation = () => {
      const varExt: string = process.env.REACT_APP_ENV == "development" ? '-dev' : process.env.REACT_APP_ENV == 'staging' ? '-stag' : '';
      const { userData } = useUser();
      return (
            <AppBar position={'sticky'} color="default" elevation={0} sx={{ display: 'flex', pl: '12px', pr: '12px', bgcolor: 'background.default' }}>
                  <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left', color: 'text.primary' }}>
                              Ultimate Football League
                        </Typography>
                        <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }} >
                              <Typography sx={{ mr: 2, color: 'gray', fontSize: 10 }}>
                                    {userData?.name}
                              </Typography>
                              <Typography sx={{ mr: 2 }}>
                                    v{process.env.REACT_APP_VERSION + varExt}
                              </Typography>
                              <ChangeThemeButton />
                              <AccountMenuPopper/>
                        </Stack>
                  </Toolbar>
            </AppBar>
      )
};

export default TopNavigation;