import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { SnackbarProvider } from 'notistack'
import { CookiesProvider } from 'react-cookie';

import store from './store';
import App from './App';
let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
      // <React.StrictMode>
      <Provider store={store}>
            <CookiesProvider>
                  <SnackbarProvider
                        autoHideDuration={3000}
                        anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                        }}>
                        <PersistGate loading={null} persistor={persistor}>
                              <App />
                        </PersistGate>
                  </SnackbarProvider>
            </CookiesProvider>
      </Provider>
      // </React.StrictMode>
);
