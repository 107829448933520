import { Navigate } from 'react-router-dom';
import route from '../configs/_route';
import { useUser } from '../features';
import React from 'react';

const ProtectedRoutes = ({ children }: React.PropsWithChildren) => {
      const { isAuth } = useUser();
      if (!isAuth) {
            return <Navigate to={route.LANDING}/>
      }
      return (
            <>
                  { children }
            </>
      )
};

export default ProtectedRoutes;