import { AxiosResponse } from "axios";
import apiUrl from "../../configs/_apiUrl";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import ApiRequest from "../../utils/apiRequest";
import { getErrorMessageResponse } from "../../utils/response";
import { 
    fetchDataFail, 
    fetchDataSuccess, 
    fetchingData, 
    submitDataFail, 
    submitDataSuccess, 
    submittingData 
} from "./_reducer";
import { MatchEvent } from "../../types/matchEvent";

const useMatchEventActions = () => {
    const dispatch = useAppDispatch();

    async function fetchMatchEvents(matchId: string) {
        const url: string = `${apiUrl.MATCH_EVENT}?matchId=${matchId}`;
        dispatch(fetchingData());
        try {
            const res: AxiosResponse = await ApiRequest.get(url);
            const data: MatchEvent[] = res.data.payload.data;
            dispatch(fetchDataSuccess({ data }));
        }
        catch(err: any) {
            console.error(err);
            const msg = getErrorMessageResponse(err);
            dispatch(fetchDataFail(msg));
        }
    }

    async function createMatchEvent(data: any) {
        const url: string = apiUrl.MATCH_EVENT;
        dispatch(submittingData());
        try {
            await ApiRequest.post(url, data);
            dispatch(submitDataSuccess());
        }
        catch(err: any) {
            console.error(err);
            const msg = getErrorMessageResponse(err);
            dispatch(submitDataFail(msg));
            const errorData = err.response?.data.errors.data
            return Promise.reject(errorData || msg);
        }
    }

    async function deleteMatchEvent(eventId: string) {
        const url = `${apiUrl.MATCH_EVENT}/${eventId}`;
        dispatch(submittingData());
        try {
            await ApiRequest.delete(url);
            dispatch(submitDataSuccess());
        }
        catch(err: any) {
            console.error(err);
            const msg = getErrorMessageResponse(err);
            dispatch(submitDataFail(msg));
            return Promise.reject(msg);
        }
    }

    return  {
        fetchMatchEvents,
        createMatchEvent,
        deleteMatchEvent
    }
}


export default useMatchEventActions;