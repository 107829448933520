import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, createMigrate } from "redux-persist";

import {
    themeSettingReducer,
    layoutSettingReducer,
    userReducer,
    breadCrumbReducer,
    newRowReducer,
    teamReducer,
    matchReducer,
    playerReducer,
    refreeReducer,
    matchEventReducer,
    dashboardReducer
} from "./features";
import { MigrationManifest } from "redux-persist/es/types";

export const reducers = combineReducers({
    theme: themeSettingReducer,
    layout: layoutSettingReducer,
    user: userReducer,
    breadCrumb : breadCrumbReducer,
    new_rows : newRowReducer,
    team : teamReducer,
    match: matchReducer,
    player : playerReducer,
    refree: refreeReducer,
    matchEvent: matchEventReducer,
    dashboard : dashboardReducer
});

const rootReducer = (state: any, action : any) => {
    if ((action.type === "user/setAuth") && action.payload == undefined) {
      window.localStorage.removeItem("vshop");
      console.log("LOGOUT..");
  
      return reducers(undefined, action);
    }
    return reducers(state, action);
};

const migrations: MigrationManifest = {
  0: (state: any) => {
    // migration clear out device state
    return {
      ...state,
      device: undefined,
    };
  },
  1: (state: any) => {
    // migration to keep only device state
    return {
      _persist: { version: 1, rehydrated: true },
      device: "Device"
    };
  },
};

const persistConfig = {
  key: "c3",
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: true }),
  // stateReconciler: hardSet,
  // whitelist: ['user', 'theme', 'layout', 'breadCrumb', 'meta_data'] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
});

export default store;