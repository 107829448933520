import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import route from "../configs/_route";
import RootLayout from "../layouts/_root";
import ProtectedRoutes from "../providers/_protectedRoutes";

//Auth
const ChangePassword = lazy(() => import("../pages/user-account/ChangePassword"));
const ResetPassword = lazy(() => import("../pages/user-account/ResetPassword"));

// Pages
const Landing = lazy(() => import("../pages/landing"));
const Player = lazy(() => import("../pages/player"));

// Dashboard
const Dashboard = lazy(() => import("../pages/dashboard"))
const AdminDashboard = lazy(() => import("../pages/dashboard/_adminDashboard"))

// Team
const Team = lazy(() => import("../pages/team"));
const TeamList = lazy(() => import("../pages/team/_list"));
const TeamDetail = lazy(() => import("../pages/team/_detail"));

// Player
const PlayerList = lazy(() => import("../pages/player/_list"));
const PlayerDetail = lazy(() => import("../pages/player/_detail"));

// Match
const Match = lazy(() => import("../pages/match"));
const MatchList = lazy(() => import("../pages/match/_list"));
const MatchDetail = lazy(() => import("../pages/match/_detail"));

//Refree
const Refree = lazy(() => import("../pages/refree"));
const RefreeList = lazy(() => import("../pages/refree/_list"));

// For Unknow Routes
const Error404 = lazy(() => import("../pages/404"));

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: route.LANDING,
      element: <Landing />,
    },
    {
      path: route.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: route.CHANGE_PASSWORD,
      element: (
        <ProtectedRoutes>
          <ChangePassword />
        </ProtectedRoutes>
      )
    },
    {
      path: "/",
      element: (
        <ProtectedRoutes>
          <RootLayout />
        </ProtectedRoutes>
      ),
      children: [
        {
          path: route.DASHBOARD,
          element: <Dashboard />,
          children: [
            {
              path: '',
              element: <AdminDashboard />
            }
          ]
        },
        {
          path: route.TEAM,
          element: <Team />,
          children: [
            {
              path: '',
              element: <TeamList />
            },
            {
              path: ':team_id',
              element: <TeamDetail />
            },
            {
              path: ':team_id/player/:player_id',
              element: <PlayerDetail />
            },
            {
              path: ':team_id/match/:match_id',
              element: <MatchDetail />
            }
          ]
        },
        {
          path: route.PLAYER,
          element: <Player />,
          children: [
            {
              path: '',
              element: <PlayerList />
            },
            {
              path: ':player_id',
              element: <PlayerDetail />
            }
          ]
        },
        {
          path: route.MATCH,
          element: <Match />,
          children: [
            {
              path: '',
              element: <MatchList />
            },
            {
              path: ":match_id",
              element: <MatchDetail />
            }
          ]
        },
        {
          path: route.REFREE,
          element: <Refree />,
          children: [
            {
              path: '',
              element: <RefreeList />
            }
          ]
        },
        {
          path: "*",
          element: <Error404 />,
        },
      ],
    },
  ]);

  return routes;
};

export default AppRoutes;
