import React from 'react';
import { responsiveFontSizes, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
// LOCAL
import NotoSan from "../assets/fonts/NotoSansMyanmar-Regular.woff2";
import { useThemeSetting } from '../features';
import { CssBaseline, PaletteMode } from '@mui/material';
import darkScrollbar from '@mui/material/darkScrollbar';
import "@fontsource/poppins";

const MMFont = {
      fontFamily: 'NotoSansMyanmar',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 400,
      src: `local('NotoSansMyanmar'),
    local('NotoSansMyanmar-Regular'),
    url(${NotoSan}) format('woff2')`,
      unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const TYThemeProvider = (props: React.PropsWithChildren): React.ReactElement => {
      const { mode } = useThemeSetting();
      const _theme = React.useMemo(() =>
            createTheme({
                  spacing: 4,
                  breakpoints: {
                        keys: ["xs", "sm", "md", "lg", "xl"],
                        values: { xs: 0, lg: 1280, sm: 600, md: 960, xl: 1920 }
                  },
                  mixins: {
                        toolbar: { minHeight: 48 }
                  },
                  // mixins:
                  // {
                  //       toolbar: { minHeight: 48 },
                  //       '@media (min-width:0px)': { minHeight: 48 },
                  //       '@media (min-width:600px)': { minHeight: 48 },
                  // },
                  typography: {
                        fontFamily: 'Noto Sans Myanmar, Arial, "Poppins"',
                        fontSize: 12,
                        fontWeightLight: 300,
                        fontWeightRegular: 400,
                        fontWeightMedium: 500,
                        fontWeightBold: 700,
                        button: {
                              fontSize: 11,
                              fontWeight: 400,
                              textTransform: 'uppercase',
                              lineHeight: 0,
                        },
                        h1: {
                              fontSize: 24,
                              fontWeight: 800,
                              margin: '13px 0px',
                        },
                        h2: {
                              fontSize: 22,
                              fontWeight: 600,
                              margin: '12px 0px',
                        },
                        h3: {
                              fontSize: 20,
                              fontWeight: 400,
                              margin: '10px 0px',
                        },
                        h4: {
                              fontSize: 18,
                              fontWeight: 400,
                              margin: '9px 0px',
                        },
                        h5: {
                              fontSize: 16,
                              fontWeight: 400,
                              margin: '9px 0px',
                        },
                        h6: {
                              fontSize: 14,
                              fontWeight: 400,
                              margin: '8px 0px',
                              color: '#777',
                        },
                        caption: {
                              fontSize: '14px',
                              fontWeight: 600,
                              margin: '8px 0px 6px 0',
                        },
                        subtitle1: {
                              fontSize: 11,
                              fontWeight: 400,
                              margin: '8px 0px',
                        },
                        subtitle2: {
                              fontSize: 10,
                              fontWeight: 400,
                              margin: '8px 0px',
                        },
                        overline: {
                              fontSize: 11,
                              fontWeight: 400,
                              textTransform: 'uppercase',
                              margin: '8px 0px',
                        },
                        body1: {
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: 1.8,
                              margin: '12px 0px'
                        },
                        body2: {
                              fontSize: '12px',
                              fontWeight: 400,
                              lineHeight: 1.8,
                              margin: '8 px 0px'
                        }
                  },
                  shape:
                  {
                        borderRadius: 6,
                  },
                  zIndex: {
                        mobileStepper: 1000,
                        speedDial: 1050,
                        appBar: 900,
                        drawer: 950,
                        modal: 1300,
                        snackbar: 1400,
                        tooltip: 1500,
                  },

                  palette: {
                        mode: mode as PaletteMode,
                        common: {
                              black: "#121212",
                              white: "#fff"
                        },
                        primary: {
                              main: mode === 'light' ? '#04080A' : '#F16E00', //'#D18B6C',
                              light: '#906A5A',
                              dark: '#351809',
                              contrastText: "rgba(255, 255, 255, 1)"
                        },
                        secondary: {
                              main: mode === 'light' ? '#F16E00' : '#04080A', //'#D18B6C',
                              light: '#906A5A',
                              dark: '#351809',
                              contrastText: "rgba(255, 255, 255, 1)"
                        },
                        background: {
                              paper: mode === 'light' ? '#FFFFFF' : '#282C34',
                              default: mode === 'light' ? '#F6F7F9' : '#282C34',
                        }
                  },
                  components: {
                        MuiCssBaseline: {
                              styleOverrides: (themeParam) => ({
                                    body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
                                    scrollbarWidth: 'none'
                              }),
                              // '@global': {
                              //       scrollbarWidth: 'none',
                              //       '@font-face': [MMFont],
                              //       html: {
                              //             WebkitFontSmoothing: 'auto',
                              //       },
                              // },
                        },
                        MuiBadge: {
                              styleOverrides: {
                                    root: {
                                          color: '#ff0000',
                                    },
                                    colorPrimary: {
                                          color: '#fff',
                                          backgroundColor: '#f44336',
                                    },
                                    colorSecondary: {
                                          color: '#fff',
                                          backgroundColor: '#f44336',
                                    },
                              }
                        },
                        MuiButton: {
                              styleOverrides: {
                                    root: {
                                          height: 28,
                                          margin: '8px 0px'
                                    },
                                    contained: {
                                          boxShadow: 'none',
                                          '&:active': {
                                                boxShadow: 'none',
                                                color: '#fff'
                                          },
                                    },
                                    outlined: {
                                          boxShadow: 'none',
                                          '&:active': {
                                                boxShadow: 'none',
                                                color: '#000'
                                          },
                                    },
                              },
                        },
                        // MuiDataGrid: {
                        //       styleOverrides: {
                        //             root: {
                        //                   paddingLeft: 16,
                        //                   paddingRight: 16,
                        //             },
                        //       },
                        // },
                        MuiTab: {
                              styleOverrides: {
                                    root: {
                                          minHeight: 30
                                    }
                              }
                        },
                        MuiTabs: {
                              styleOverrides: {
                                    root: {
                                          minHeight: 30
                                    }
                              }
                        },
                        MuiFormHelperText: {
                              styleOverrides: {
                                    root: {
                                          fontSize: '10px',
                                          fontWeight: 400,
                                    }
                              }
                        },
                        // MuiInputBase: {
                        //       styleOverrides: {
                        //             root: {
                        //                   marginTop: 3,
                        //                   marginBottom: 0,
                        //             }
                        //       }
                        // }
                  },
                  // eslint-disable-next-line
            }), [mode]);

      const ThemeWithResponsiveFont = responsiveFontSizes(_theme);

      return (
            <StyledEngineProvider injectFirst>
                  <CssBaseline />
                  <ThemeProvider theme={ThemeWithResponsiveFont}>
                        {props.children}
                  </ThemeProvider>
            </StyledEngineProvider>
      )
}


export default TYThemeProvider;