import useBreadCrumbActions  from './_actions';
import { useAppSelector } from "../../hooks/useRedux";

export const useBreadCrumb = () => {
    const {
        setBreadCrumbStep,
    } = useBreadCrumbActions();

    const steps = useAppSelector((state) => state.breadCrumb.steps);

    return {
        //state
        steps,

        //actions
        setBreadCrumbStep,
    }
}

export {default as breadCrumbReducer} from './_reducers';