import React from 'react';
import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface SingleNumberProps { 
      title: string;
      value: number;
      unit: string;
      isOutlined: boolean;
}

export default function SingleNumber({  title, value, unit, isOutlined } : SingleNumberProps) {
      return (
            <Card  sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', p: 4 }} variant={isOutlined ? "outlined" : "elevation"}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Typography variant='caption'>{title}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', width: '100%' }}>
                        <Typography sx={{ fontSize: '26px', color: 'gray', fontWeight: 600, m: 0 }}>{value}</Typography>
                        <Typography sx={{ fontSize: '10px', fontWeight: 400, m: 0 }}>{unit}</Typography>
                  </Box>
            </Card>
      );
}
