import {
      Card, Typography,
      // useTheme 
} from '@mui/material';
import { Box } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import React from 'react';
// LOCAL
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartType = {
      title?: string;
      data: Object;
}

/**
 * 
 * @param {String} title PieChart's title
 * @param {Object} data format --> { label: value, label: value }
 * @returns 
 */
function PieChart({ title, data }: PieChartType) {
      // const theme = useTheme();

      // yelow, green, red,
      // const backgroundColor = ['#ffff80', '#80ff80', '#ff6666']

      const options: ChartOptions<"doughnut"> = {
            responsive: true,
            plugins: {
                  legend: { position: "center" }
            }
      }

      function getTotal(initData: Object): number {
            let total = 0;
            Object.entries(initData).map((d) => { total += Number(d[1]) })
            return total
      }

      function generateData(initData: Object) {
            let label: string[] = [];
            let data: number[] = [];
            let backgroundColor: string[] = [];

            Object.entries(initData).map((d) => {
                  label.push(d[0]) // lable
                  data.push(d[1]) // values

                  // Assign background color based on status
                  switch (d[0]) {
                        case 'active':
                        case 'completed':
                              backgroundColor.push('#F87B14'); // orange
                              break;
                        case 'inactive':
                        case 'scheduled':
                              backgroundColor.push('#FFD6BD'); // light orange
                              break;
                        case 'draft':
                              backgroundColor.push('#EBEBEB'); // gray
                              break;
                        default:
                              backgroundColor.push('#000000'); // fallback color
                  }
            })

            const _data = {
                  labels: label,
                  datasets: [
                        {
                              data: data,
                              backgroundColor: backgroundColor,
                              borderWidth: 0,
                        },
                  ],
            };

            return _data
      }

      return (
            <Card sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: 150, p: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Typography variant='caption'>{title}</Typography>
                        {
                              getTotal(data) > 0
                                    ? <Typography sx={{ fontSize: '30px', fontWeight: 600 }}>{getTotal(data)}</Typography>
                                    : <Typography sx={{ fontSize: '15px', color: 'lightgray', fontWeight: 600, mt: 8 }}>{"Empty"}</Typography>
                        }
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        {
                              getTotal(data) > 0
                                    ? <Doughnut options={options} data={generateData(data)} />
                                    : <Box sx={{ background: '#E9E9E9', width: 118, height: 118, borderRadius: 100 }} />
                        }
                  </Box>
            </Card>
      );
}

export default PieChart; 