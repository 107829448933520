import React from 'react';
import { Box } from '@mui/system';
import { List } from '@mui/material';
import { MenuListItem } from '../components';

type ActionType = {
      [key: string] : boolean
}

type DataSchemaType = {
      collectionName: string;
      menuName: string;
      iconName: string;
      fe_route? : string;
      isMenuShow: boolean;
      actions: ActionType
}

type DataType = {
      id: number;
      menuName: string;
      iconName: string;
      isMenuShow: boolean;
      fe_route?: string;
      actions? : ActionType;
      children?: DataSchemaType[];
}

const data : DataType[] = [
      {
            id: 1,
            menuName: 'Dashboard',
            iconName: 'dashboard',
            isMenuShow: true,
            fe_route: 'dashboard',
      }
      ,
      {
            id: 3,
            menuName: 'Team',
            iconName: 'groups',
            isMenuShow: true,
            fe_route: 'teams',
      },
      {
            id: 4,
            menuName: 'Player',
            iconName: 'sports_handball',
            isMenuShow: true,
            fe_route: 'players',
      },
      {
            id: 4,
            menuName: 'Match',
            iconName: 'sports_soccer',
            isMenuShow: true,
            fe_route: 'matches',
      },
      {
            id: 5,
            menuName: 'Refree',
            iconName: 'sports',
            isMenuShow: true,
            fe_route: 'referees'
      }
]

const LeftNavigation = (): React.ReactElement => {
      return (
            <Box sx={{ flexGrow: 1, flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto', '::-webkit-scrollbar': { display: 'none' } }}>
                  <nav>
                        <List sx={{ width: '100%', height: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                              {
                                    data.map((item, index) => 
                                          item.isMenuShow &&
                                          <MenuListItem
                                                key={index}
                                                route={item.fe_route ?? ""}
                                                icon={item.iconName}
                                                title={item.menuName}
                                                child={item.children?.filter(x => x.isMenuShow === true) ?? []}
                                          />
                                    )
                              }
                        </List>
                  </nav>
            </Box >
      )
}

export default LeftNavigation;
