import { Box, Button, Collapse, CollapseProps, Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingButton from '../Buttons/LoadingButton'

type FadeInCollapsePropsType = {
    in: boolean;
    title : string;
    onDiscard : () => void;
    handleOnClick: () => void;
    isLoading : boolean;
    disabled : boolean;
    sx? : CollapseProps["sx"];
}

const FadeInCollapse = (props : FadeInCollapsePropsType) => {
      
  return (
    <Collapse in={props.in} sx={{ ...props.sx, height: '100%', width: '100%', bgcolor: '#E0FBE2', px: 2 }}>
          <Box sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'space-between', px: 10 }}>
                <Typography variant="h6">{props.title}</Typography>
                <Stack direction={'row'} gap={3}>
                      <Button variant="outlined" onClick={props.onDiscard}>Discard</Button>
                      <LoadingButton
                            label="Save"
                            isLoading={props.isLoading}
                            disabled={props.disabled}
                            variant="contained"
                            onClick={props.handleOnClick}
                      />
                </Stack>
          </Box>
    </Collapse>
  )
}

export default FadeInCollapse