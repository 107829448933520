import React from 'react';
import { Box, Typography } from '@mui/material';
import DancingTotoro from "../../assets/images/totoro/dancing.gif"

const EmptyBox = () => {
      return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: 'transparent', mt: 10, mb: 10 }}>
                  <img style={{ width: 160, height: 160 }} alt={'img'} src={DancingTotoro} />
                  <Typography variant='h4'>No Data</Typography>
            </Box>
      )
};

export default EmptyBox;