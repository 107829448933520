import Axios from 'axios';
import tokenName from '../constants/token';

type reactEnv = "production" | "development";

const env: reactEnv = process.env.REACT_APP_ENV as reactEnv || "local";
enum API_BASE_URLS {
    production = 'https://api.ufl7.com',
    development = 'https://api.caringcodecreation.com',
    local = 'http://localhost:3000'
}

const API_BASE_URL: string = API_BASE_URLS[env];
const API_VERSION: string = "v1";

const ApiRequest = Axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    baseURL: `${API_BASE_URL}/${API_VERSION}`
});

async function requestNewTokens(): Promise<{ accessToken: string, refreshToken: string }> {
    const refreshToken = localStorage.getItem(tokenName.REFRESH_TOKEN);
    const apiRes = await ApiRequest.post('/auth/refresh-token', { refreshToken });
    const resData = apiRes.data.payload.data;
    return resData;
}

ApiRequest.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        let res = err.response;
        try {
            const isTokenExpired = res.data?.errors?.data?.tokenExpired;
            if (!err.config?.url.includes('login') && res?.status == 401 && isTokenExpired) {
                const { accessToken, refreshToken } = await requestNewTokens();
                localStorage.setItem(tokenName.ACCESS_TOKEN, accessToken);
                localStorage.setItem(tokenName.REFRESH_TOKEN, refreshToken);

                err.config.headers = {
                    ...err.config.headers,
                    authorization: `Bearer ${accessToken}`,
                };

                return Axios(err.config);
            }
            else {
                throw err;
            }
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
);

ApiRequest.interceptors.request.use(
    req => {
        const token = localStorage.getItem(tokenName.ACCESS_TOKEN);
        req.headers.Authorization = `Bearer ${token}`;
        return req;
    }
)

export default ApiRequest;